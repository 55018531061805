import { useState, useEffect } from 'react'
import {
  sendTeamInvitation, openCheckoutSessionInNewTab,
  openCustomerPortalSessionInNewTab,
  confirmTeamInvitation,
  rejectTeamInvitation,
  removeMyselfFromTeam,
  removeTeamMember,
  deleteRejectedInvite
} from '../../utils/genericUtils';
import { useUserData } from '../hooks/useUserData';

import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { Tooltip } from 'react-tooltip'

import { ButtonWithTimeoutReplaceOnClick } from '../../utils/ButtonWithTimeoutReplaceOnClick'

const MySwal = withReactContent(Swal)


function validateEmail(email) {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
}

export function SettingsTabSubscriptions({}) {

  const [
    { profile, subscription_is_active, pending_invites, admin_user, managed_users_not_confirmed, managed_users, admin_user_subscription_is_active, briefly_ai_credits_left,
      subscription_cancels_at_period_end, current_monthly_bill, billing_period_end, users_who_rejected, was_upgraded_to_team, min_seats, forecasted_monthly_bill, coupon_percentage_applied,
      subscription_plan, coupon_fixed_price_applied, email, coupon_percentage_applied_team_members
    },
    forceRenewUserData
  ] = useUserData()

  const members = []

  const [showAddTeamMemberPopup, setShowAddTeamMemberPopup] = useState(false)
  const [csvEmailsToBeInvites, setCsvEmailsToBeInvited] = useState("")

  const emailsToBeInvited = csvEmailsToBeInvites.split(',').map(x => x.trim().toLowerCase()).filter(x => x)

  const emailsAreValid = emailsToBeInvited.length > 0 && !emailsToBeInvited.find(x => !validateEmail(x))

  const isOutOfSeats = managed_users.concat(managed_users_not_confirmed).length >= min_seats

  const isTeamMemberAccount = !!admin_user

  const isProGivenForFree = subscription_plan === 'STANDARD_GIVEN_FOR_FREE' && !isTeamMemberAccount

  const isEnterprise = (subscription_is_active || isProGivenForFree) && !!min_seats

  const isTeamAccount = (isEnterprise || (managed_users.concat(managed_users_not_confirmed).concat(users_who_rejected)).length > 0 || was_upgraded_to_team)

  const pendingInvite = pending_invites?.[0]

  const seatPrice = 15 * (100 - coupon_percentage_applied) * (100 - coupon_percentage_applied_team_members) / 100 / 100
  const PRICE_PER_SEAT = seatPrice

  function formatDate(inputDate) {
    const date = new Date(inputDate);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Month is zero-based
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  }

  const stripeSubscriptionState = subscription_is_active && !isTeamMemberAccount && !isProGivenForFree ? <div className="row" id="stripe-subscription-state" style={{ alignItems: 'center' }}>
    <span>
      {subscription_cancels_at_period_end ? `Membership cancelled: ends on ${formatDate(billing_period_end)}` : `Monthly billing renews on ${formatDate(billing_period_end)}`}
    </span>
    <span>
      ${(Math.round((100 - coupon_percentage_applied) * forecasted_monthly_bill - coupon_fixed_price_applied) / 100).toFixed(2)}
      <svg html={<>
        <b>How is this calculated?</b><br />
        Monthly charges are prorated at ${seatPrice} per user. If you add or remove team members during the month, you'll only pay for the time they were part of the team.
      </>} data-tooltip-id={"tooltip-payment"} style={{ display: 'inline-block', marginLeft: 5 }} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.33099 5.5493C8.70657 5.17371 8.89437 4.723 8.89437 4.19718C8.89437 3.53991 8.65962 2.97653 8.19014 2.50704C7.72066 2.03756 7.15728 1.80282 6.5 1.80282C5.84272 1.80282 5.27934 2.03756 4.80986 2.50704C4.48644 2.83046 4.27442 3.19844 4.1738 3.61098C4.09638 3.92838 4.37048 4.19718 4.69718 4.19718V4.19718C5.02389 4.19718 5.27519 3.91515 5.43788 3.63184C5.49425 3.53368 5.5666 3.44044 5.65493 3.35211C5.89906 3.10798 6.18075 2.98592 6.5 2.98592C6.81925 2.98592 7.10094 3.10798 7.34507 3.35211C7.5892 3.59624 7.71127 3.87793 7.71127 4.19718C7.71127 4.51643 7.5892 4.79812 7.34507 5.04225L6.61268 5.80282C6.14319 6.30986 5.90845 6.87324 5.90845 7.49296V7.49296C5.90845 7.66409 6.04718 7.80282 6.21831 7.80282H6.5C6.8267 7.80282 7.0831 7.53365 7.16565 7.21755C7.26853 6.82354 7.47858 6.45525 7.79578 6.11268L8.33099 5.5493ZM6.5 10.1972C6.8267 10.1972 7.09155 9.93234 7.09155 9.60563V9.60563C7.09155 9.27893 6.8267 9.01408 6.5 9.01408V9.01408C6.1733 9.01408 5.90845 9.27893 5.90845 9.60563V9.60563C5.90845 9.93234 6.1733 10.1972 6.5 10.1972V10.1972ZM2.24648 1.77465C3.42958 0.591549 4.84742 0 6.5 0C8.15258 0 9.56103 0.591549 10.7254 1.77465C11.9085 2.93897 12.5 4.34742 12.5 6C12.5 7.65258 11.9085 9.07042 10.7254 10.2535C9.56103 11.4178 8.15258 12 6.5 12C4.84742 12 3.42958 11.4178 2.24648 10.2535C1.08216 9.07042 0.5 7.65258 0.5 6C0.5 4.34742 1.08216 2.93897 2.24648 1.77465Z" fill="#C6C6C6" />
      </svg>
      <Tooltip id={"tooltip-payment"} place="top" content={"hi"} html={`
            <div style="max-width: 220px; text-align: left; line-height: 18px; letter-spacing: 0.3px;">
              <b>How is this calculated?</b><br/><br/>
              <span style="color: #C6C6C6">Monthly charges are prorated at $${seatPrice} per user. If you add or remove team members during the month, you'll only pay for the time they were part of the team.</span>
            </div>
          `} style={{ background: '#2c2c2c' }}>
        hello
      </Tooltip>
    </span>
  </div> : ''

  const teamMemberPopup = <div className="transcript-info-popup-container">
    <div className="popup-background" style={{}} onClick={() => setShowAddTeamMemberPopup(false)}></div>
    <div className="popup-content">
      <div className="close-icon" onClick={() => setShowAddTeamMemberPopup(false)}>
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15.1946 0.805369C15.6394 1.25016 15.6394 1.97131 15.1946 2.41611L9.61074 8L15.1946 13.5839C15.6394 14.0287 15.6394 14.7498 15.1946 15.1946C14.7498 15.6394 14.0287 15.6394 13.5839 15.1946L8 9.61074L2.41611 15.1946C1.97132 15.6394 1.25016 15.6394 0.805369 15.1946C0.360576 14.7498 0.360576 14.0287 0.805369 13.5839L6.38926 8L0.80537 2.41611C0.360577 1.97132 0.360576 1.25016 0.805369 0.805369C1.25016 0.360576 1.97131 0.360576 2.41611 0.805369L8 6.38926L13.5839 0.80537C14.0287 0.360577 14.7498 0.360576 15.1946 0.805369Z" fill="white" />
        </svg>
      </div>
      <h1>{isEnterprise && isOutOfSeats ? 'You’ve run out of available seats' : 'Add members to your team'}</h1>


      {isEnterprise ? <span className="gray-text" style={{
        fontFamily: 'Roboto',
        fontSize: 14,
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '20px',
        letterSpacing: '0.15px',

      }}>
        Your enterprise membership includes {min_seats} seats
      </span> : ''}

      {isEnterprise ? <>
        <span className="gray-text" style={{
          fontFamily: 'Roboto',
          fontSize: 14,
          fontStyle: 'normal',
          fontWeight: 500,
          lineHeight: '20px',
          letterSpacing: '0.15px'
        }}>
          You can add extra members for ${seatPrice} each per month
        </span>
      </> : ''}

      {!isEnterprise && !isOutOfSeats ? <div className="row">
        <svg style={{ width: 19, marginRight: 8, marginTop: 2 }} width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clipPath="url(#clip0_1023_50429)">
            <path d="M6 7.09155C6.3267 7.09155 6.59155 6.8267 6.59155 6.5V4.07746C6.59155 3.75076 6.3267 3.48592 6 3.48592C5.6733 3.48592 5.40845 3.75076 5.40845 4.07746V6.5C5.40845 6.8267 5.6733 7.09155 6 7.09155ZM6 9.51408C6.3267 9.51408 6.59155 9.24924 6.59155 8.92253V8.89437C6.59155 8.56766 6.3267 8.30282 6 8.30282C5.6733 8.30282 5.40845 8.56766 5.40845 8.89437V8.92253C5.40845 9.24924 5.6733 9.51408 6 9.51408ZM1.74648 2.27465C2.92958 1.09155 4.34742 0.5 6 0.5C7.65258 0.5 9.06103 1.09155 10.2254 2.27465C11.4085 3.43897 12 4.84742 12 6.5C12 8.15258 11.4085 9.57042 10.2254 10.7535C9.06103 11.9178 7.65258 12.5 6 12.5C4.34742 12.5 2.92958 11.9178 1.74648 10.7535C0.58216 9.57042 0 8.15258 0 6.5C0 4.84742 0.58216 3.43897 1.74648 2.27465Z" fill="white" />
          </g>
          <defs>
            <clipPath id="clip0_1023_50429">
              <rect width="12" height="12" fill="white" transform="translate(0 0.5)" />
            </clipPath>
          </defs>
        </svg>
        <span className="gray-text" style={{ marginTop: 0, fontWeight: 400 }}>
          Each new member will be added to your plan as a PRO member {isEnterprise ? `at ${seatPrice}/user/month` : ''} and your billing will be updated accordingly.
        </span>
      </div> : ''}

      <div>
        <input value={csvEmailsToBeInvites} onChange={e => setCsvEmailsToBeInvited(e.target.value)} type="text" placeholder="name@email.com, name2@email.com, name3@email.com" />
      </div>

      <button onClick={() => {

        if (emailsToBeInvited.includes(email)) {
          // setShowAddTeamMemberPopup(false)
          Swal.fire({
            icon: 'info',
            title: 'Oops...',
            text: `${email} can't be added to the team as it’s already the team admin`,
            background: '#eee'
          })
          return;
        }

        if (managed_users.concat(managed_users_not_confirmed).find(x => emailsToBeInvited.includes(x))) {
          // setShowAddTeamMemberPopup(false)
          Swal.fire({
            icon: 'info',
            title: 'Oops...',
            text: `${managed_users.concat(managed_users_not_confirmed).find(x => emailsToBeInvited.includes(x))} can't be added to the team as it’s already a member`,
            background: '#eee'
          })
          return;
        }

        sendTeamInvitation(emailsToBeInvited, () => {
          setShowAddTeamMemberPopup(false)
          forceRenewUserData()
        })
        setCsvEmailsToBeInvited("")
      }} style={{ marginTop: 20, marginBottom: 5, width: '160px !important' }} disabled={!emailsAreValid}>
        {isEnterprise && isOutOfSeats ? `Invite extra members for $${seatPrice}/user/month` : 'Send invitation'}
      </button>
    </div>
  </div>

  const pendingInviteSection = pendingInvite ? <div id="pending-invite-container" style={{
    borderRadius: 4,
    background: '#2C2C2C',
    padding: 24,
    marginBottom: 20
  }}>
    <h2 style={{
      fontSize: 16,
      fontWeight: 900
    }}>You’ve been invited to join a team!</h2>

    <p className="gray-text">
      By accepting this invitation you will be added to {pendingInvite} team on Briefly as a PRO user. {isTeamAccount ? 'Your TEAM account will terminate and y' : subscription_is_active ? 'Your PRO account will terminate and y' : 'Y'}our monthly fee will be covered by {pendingInvite} account.
    </p>

    <div className="row" style={{ marginTop: 10, width: 220 }}>
      <button className="reject-btn" onClick={() => rejectTeamInvitation(pendingInvite, () => {
        forceRenewUserData()
      })} style={{ padding: '7px 30px', fontWeight: 600 }}>
        Reject
      </button>
      <button className="accept-btn" onClick={() => confirmTeamInvitation(pendingInvite, () => {
        forceRenewUserData()
      })} style={{ padding: '7px 30px', fontWeight: 600 }}>
        Accept
      </button>
    </div>
  </div> : ''

  return <div id="settings-subscriptions">
    {showAddTeamMemberPopup ? teamMemberPopup : ''}

    {subscription_is_active || isTeamMemberAccount || isProGivenForFree ? <>
      <div className="row">
        <h2>Subscriptions</h2>
        <div className="badge" style={isEnterprise ? { width: 72 } : {}}>{isEnterprise ? 'ENTERPRISE' : isTeamAccount || isTeamMemberAccount ? 'TEAM' : 'PRO'}</div>
      </div>
      {pendingInviteSection}
      <p className="gray-text">
        You are currently subscribed to Briefly {isEnterprise ? 'Enterprise' : isTeamAccount || isTeamMemberAccount ? 'TEAM' : isProGivenForFree ? 'PRO (FREE)' : 'PRO'}{isProGivenForFree ? '' : ','} {isProGivenForFree ? '' : isTeamMemberAccount ? `${admin_user} is managing your subscription` : 'you can manage your membership on Stripe'}
      </p>

      {(admin_user && !admin_user_subscription_is_active) ? <p className="gray-text">
        Subscription is not active. Please tell account manager to renew subscription
      </p> : ''}

      {!isTeamMemberAccount && subscription_is_active && !isProGivenForFree ? <div>
        <button onClick={() => openCustomerPortalSessionInNewTab()}>
          Manage my membership
        </button>
        <button className="alt-color" onClick={() => window.open('https://www.brieflyai.com/contact-us', '_blank')} style={{ padding: '7px 30px', fontWeight: 600, marginLeft: 10 }}>
          Contact support
        </button>

      </div> : isProGivenForFree ? '' : <button onClick={() => {

        Swal.fire({
          title: `Are you sure you want to be removed from ${admin_user} team account?`,
          text: "By doing so you will loose all Briefly Pro account privileges and your account will be switched to a Free subscription",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#d33',
          cancelButtonColor: '#3085d6',
          confirmButtonText: 'Yes, remove my team membership',
          cancelButtonText: 'No, keep my membership',
          background: '#eee'
        }).then((result) => {
          if (result.isConfirmed) {
            removeMyselfFromTeam(admin_user, () => {
              forceRenewUserData()
            })
          }
        })
      }}>
        Remove team membership
      </button>}

      {!isTeamMemberAccount && !isProGivenForFree ? <hr style={{ margin: '20px 0', background: '#434343' }} /> : ''}
    </> : <><h2>Subscriptions</h2> {pendingInviteSection} </>}

    {!(subscription_is_active || (isTeamMemberAccount && admin_user_subscription_is_active)) && !isProGivenForFree ? <div style={{
      color: '#C6C6C6',
      fontSize: 14,
      lineHeight: '20px'
    }}>
      {(isTeamMemberAccount && !admin_user_subscription_is_active) ? <hr style={{ margin: '20px 0', background: '#434343' }} /> : ''}
      <b>{briefly_ai_credits_left} free AI credits left</b>
      <br />
      <span>AI summary, BrieflyCreate Output or email follow up generation, will use 1 AI credit. Transcriptions and transcript uploads are unlimited on your free plan.</span>
    </div> : ''}

    {subscription_is_active && !isTeamAccount ? stripeSubscriptionState : ''}

    {!isTeamMemberAccount && (subscription_is_active || isProGivenForFree) ? <div className="row">
      <div>
        <h2 style={{ margin: 0 }}>
          {isTeamAccount ? 'Team' : 'Switch to team account'}
        </h2>
        <span className="gray-text" style={{
          fontSize: 10, fontWeight: 400, letterSpacing: '0.4px', fontFamily: 'Roboto',
          fontSize: 12,
          fontStyle: 'normal',
          fontWeight: 400,
          lineHeight: '18px',
          letterSpacing: '0.3px'
        }}>
          {!isTeamAccount ? <>By switching you’ll be billed ${PRICE_PER_SEAT}.00 per month plus ${PRICE_PER_SEAT}.00 per month for each member added</> : isEnterprise ? <>
            <span>{min_seats} total seats available with your plan</span>
            <span style={{ background: '#434343', color: '#C6C6C6' }} className="gray badge">{Math.min(min_seats, managed_users.concat(managed_users_not_confirmed).length)} used</span>
            <span style={{ background: '#B8B8B8', color: '#434343' }} className="gray badge">{Math.max(0, min_seats - managed_users.concat(managed_users_not_confirmed).length)} available</span>
            {managed_users.concat(managed_users_not_confirmed).length > min_seats ? <span className="gray badge" style={{ background: 'white', color: '#434343' }}>{managed_users.concat(managed_users_not_confirmed).length - min_seats} extra added</span> : ''}
          </> : ''}
        </span>
      </div>
      <div>
        <button onClick={() => setShowAddTeamMemberPopup(true)} style={{ padding: '7px 30px', fontWeight: 600, minWidth: 168, marginLeft: 15 }}>
          <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.5 6C12.5 6.46703 12.1214 6.84564 11.6544 6.84564H7.34564V11.1544C7.34564 11.6214 6.96703 12 6.5 12V12C6.03297 12 5.65436 11.6214 5.65436 11.1544V6.84564H1.34564C0.878606 6.84564 0.5 6.46703 0.5 6V6C0.5 5.53297 0.878605 5.15436 1.34564 5.15436H5.65436V0.845639C5.65436 0.378606 6.03297 0 6.5 0V0C6.96703 0 7.34564 0.378605 7.34564 0.845638V5.15436H11.6544C12.1214 5.15436 12.5 5.53297 12.5 6V6Z" fill="white" />
          </svg>
          Add member
        </button>
        <span className="gray-text" style={{ fontSize: 10, fontWeight: 400, letterSpacing: '0.4px', textAlign: 'right' }}>
          {isTeamAccount && !isEnterprise ? <>${PRICE_PER_SEAT}.00 per member per month</> : ''}
        </span>
      </div>
    </div> : isTeamMemberAccount || isProGivenForFree ? '' : <div className="row" style={{ marginTop: 30 }}>
      <div>
        <h2 style={{ margin: 0 }}>
          Upgrade to PRO
        </h2>
        <span className="gray-text" style={{ fontSize: 12, fontWeight: 400, letterSpacing: '0.4px' }}>
          Upgrade to PRO account for ${PRICE_PER_SEAT}.00 per month
        </span>
      </div>
      <div>
        <button onClick={() => openCheckoutSessionInNewTab()} style={{ padding: '7px 30px', fontWeight: 600 }}>
          Upgrade to PRO
        </button>
      </div>
    </div>}

    {(subscription_is_active || isProGivenForFree) && isTeamAccount ? <>
      <hr style={{ margin: '20px 0', background: '#434343' }} />
      {stripeSubscriptionState}
    </> : ''}


    {isTeamAccount ? <div id="team-user-table">
      <div className="header row">
        <h3>Email</h3>
        <h3>Role</h3>
        <div></div>
      </div>
      <div className="row">
        <div>{profile?.email}</div>
        <div>Admin</div>
        <div></div>
      </div>
      {managed_users.map(email => {
        return <>
          <hr style={{ background: '#434343' }} />
          <div className="row">
            <div>{email}</div>
            <div>Team Member</div>
            <div><a onClick={() => {
              removeTeamMember(email, () => {
                forceRenewUserData()
              })
            }} className="remove-btn">Remove</a></div>
          </div>
        </>
      })}
      {managed_users_not_confirmed.filter(x => !managed_users.includes(x)).map(email => {
        return <>
          <hr style={{ background: '#434343' }} />
          <div className="row">
            <div>{email}</div>
            <div>Team Member</div>
            <div className="row" style={{ margin: 0, minWidth: 150 }}>
              <div>Pending...</div>
              <ButtonWithTimeoutReplaceOnClick

                className="remove-btn"

                style={{ background: 'transparent', whiteSpace: 'nowrap', marginLeft: 3, marginRight: 3, padding: 0, textAlign: 'center', width: 'unset' }}

                initialText={
                  <>Re-send invite</>
                }

                clickedText={
                  <>Email Sent</>
                }

                onClick={() => {
                  sendTeamInvitation([email], () => {
                    forceRenewUserData()
                  })
                }}

              />
              <div style={{ display: 'inline-block', position: 'relative', left: 5, top: 2, cursor: 'pointer', width: 12 }} onClick={() => {
                removeTeamMember(email, () => {
                  forceRenewUserData()
                })
              }}>
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.99664 1.00336C10.2746 1.28135 10.2746 1.73207 9.99664 2.01007L6.50671 5.5L9.99664 8.98993C10.2746 9.26793 10.2746 9.71865 9.99664 9.99664C9.71865 10.2746 9.26793 10.2746 8.98993 9.99664L5.5 6.50671L2.01007 9.99664C1.73207 10.2746 1.28135 10.2746 1.00336 9.99664C0.72536 9.71865 0.72536 9.26793 1.00336 8.98993L4.49329 5.5L1.00336 2.01007C0.72536 1.73207 0.72536 1.28135 1.00336 1.00336C1.28135 0.72536 1.73207 0.72536 2.01007 1.00336L5.5 4.49329L8.98993 1.00336C9.26793 0.725359 9.71865 0.72536 9.99664 1.00336Z" fill="white" />
                </svg>
              </div>
            </div>
          </div>
        </>
      })}
      {users_who_rejected.map(email => {
        return <>
          <hr style={{ background: '#434343' }} />
          <div className="row">
            <div>{email}</div>
            <div>Team Member</div>
            <div className="row" style={{ margin: 0, minWidth: 150 }}>
              <div>Rejected</div>
              <ButtonWithTimeoutReplaceOnClick

                className="remove-btn"

                style={{ background: 'transparent', whiteSpace: 'nowrap', marginLeft: 3, marginRight: 3, padding: 0, textAlign: 'center', width: 'unset' }}

                initialText={
                  <>Re-send invite</>
                }

                clickedText={
                  <>Email Sent</>
                }

                onClick={() => {
                  sendTeamInvitation([email], () => {
                    forceRenewUserData()
                  })
                }}

              />
              <div style={{ display: 'inline-block', position: 'relative', left: 5, top: 2, cursor: 'pointer', width: 12 }} onClick={() => {
                deleteRejectedInvite(email, () => {
                  forceRenewUserData()
                })
              }}>
                <svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9.99664 1.00336C10.2746 1.28135 10.2746 1.73207 9.99664 2.01007L6.50671 5.5L9.99664 8.98993C10.2746 9.26793 10.2746 9.71865 9.99664 9.99664C9.71865 10.2746 9.26793 10.2746 8.98993 9.99664L5.5 6.50671L2.01007 9.99664C1.73207 10.2746 1.28135 10.2746 1.00336 9.99664C0.72536 9.71865 0.72536 9.26793 1.00336 8.98993L4.49329 5.5L1.00336 2.01007C0.72536 1.73207 0.72536 1.28135 1.00336 1.00336C1.28135 0.72536 1.73207 0.72536 2.01007 1.00336L5.5 4.49329L8.98993 1.00336C9.26793 0.725359 9.71865 0.72536 9.99664 1.00336Z" fill="white" />
                </svg>
              </div>
            </div>
          </div>
        </>
      })}
    </div> : ''}
  </div>
}

export default SettingsTabSubscriptions
