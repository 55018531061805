// SettingsTabIntegrations.js
import React, { useEffect, useState } from 'react';
import { useUserData } from '../hooks/useUserData';
import { disconnectHubspot, trackEvent, updateUserSettings, getUserAccessToken, disconnectGoogleCalendar, disconnectGoogleDrive, authCalendar, authDrive, hubspotInitiateOauth, disconnectSalesforce, setHubspotIntegrationIsActive, setHubspotGrowthOpportunitiesActive, setSalesforceIntegrationIsActive, setSalesforceGrowthOpportunitiesActive } from '../../utils/genericUtils';
import { useNavigate, useParams } from 'react-router-dom';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { Tooltip } from 'react-tooltip'
import LinkWithStaticInfoPopup from '../controls/LinkWithStaticInfoPopup'
import GoogleCalendarIcon from '../../images/google_calendar_icon.png'
import GoogleDriveIcon from '../../images/google_drive_icon.png'
import GoogleMeetIcon from '../../images/google_meet_icon.png'
import ZoomIcon from '../../images/zoom_icon.png'
import SalesforceSetupInfo1 from '../../images/salesforce-setup.png'
import SalesforceSetupInfo2 from '../../images/salesforce-setup-2.png'
import SalesforceSetupInfo3 from '../../images/salesforce-setup-3.png'
import SlackIcon from '../../images/slack_icon.png'
import HubspotIcon from '../../images/hubspot_icon.png'
import SalesforceIcon from '../../images/salesforce_icon.png'
import Radio from '../controls/Radio'
import Checkbox from '../controls/Checkbox'
import { useDebounce } from 'use-debounce';
import { SLACK_CLIENT_ID, HUBSPOT_CONNECT_LINK, SALESFORCE_CONNECT_LINK } from '../../config/config'
import { ThreeDots } from 'react-loader-spinner'

function connectedToEmailBadge(email) {
  var icon;
  if (email === 'Not connected') {
    icon = <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.896 0.604027C12.2296 0.937622 12.2296 1.47849 11.896 1.81208L7.70805 6L11.896 10.1879C12.2296 10.5215 12.2296 11.0624 11.896 11.396V11.396C11.5624 11.7296 11.0215 11.7296 10.6879 11.396L6.5 7.20805L2.31208 11.396C1.97849 11.7296 1.43762 11.7296 1.10403 11.396V11.396C0.770432 11.0624 0.770432 10.5215 1.10403 10.1879L5.29195 6L1.10403 1.81208C0.770432 1.47849 0.770432 0.937622 1.10403 0.604027V0.604027C1.43762 0.270432 1.97849 0.270432 2.31208 0.604027L6.5 4.79195L10.6879 0.604027C11.0215 0.270432 11.5624 0.270432 11.896 0.604027V0.604027Z" fill="white" />
    </svg>
  } else {
    icon = <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.28873 9.01408L10.6972 3.60563L9.85211 2.73239L5.28873 7.29577L3.14789 5.15493L2.30282 6L5.28873 9.01408ZM2.24648 1.77465C3.42958 0.591549 4.84742 0 6.5 0C8.15258 0 9.56103 0.591549 10.7254 1.77465C11.9085 2.93897 12.5 4.34742 12.5 6C12.5 7.65258 11.9085 9.07042 10.7254 10.2535C9.56103 11.4178 8.15258 12 6.5 12C4.84742 12 3.42958 11.4178 2.24648 10.2535C1.08216 9.07042 0.5 7.65258 0.5 6C0.5 4.34742 1.08216 2.93897 2.24648 1.77465Z" fill="white" />
    </svg>
  }

  return <div className="connected-to-email-badge">
    {icon}

    {email ? email : 'Connected'}
  </div>
}

function SettingsTabCalendarIntegration({ calendarIsIntegrated, forceRenewUserData, email_google_calendar }) {
  function onCalendarLoginSuccess(data) {

    authCalendar(data.code, calendarData => {
      setTimeout(() => {
        forceRenewUserData()
      }, 500)
    })
  }

  const calendarLogin = useGoogleLogin({
    onSuccess: codeResponse => onCalendarLoginSuccess(codeResponse),
    flow: 'auth-code',
    scope: "openid profile email https://www.googleapis.com/auth/calendar.readonly"
  });

  return <div className="email-notifications-switch">
    <span style={{ marginTop: 2 }}>
      <img src={GoogleCalendarIcon} width="18" /> <span className="integration-name">Google Calendar™</span> {calendarIsIntegrated ? connectedToEmailBadge(email_google_calendar) : ''}
      <span className="gray-text">Connect your calendar to interact with other call participants.</span>
    </span>
    <button className={calendarIsIntegrated && 'connected'} style={{}} onClick={calendarIsIntegrated ? disconnectGoogleCalendar : () => {
      trackEvent('briefly-connect-calendar-clicked', {})
      calendarLogin()
    }}>
      {calendarIsIntegrated ? "Disconnect" : "Connect"}
    </button>
  </div>


}

function SettingsTabCalendarIntegrationInPopup({ calendarIsIntegrated, forceRenewUserData, email_google_calendar, style = {} }) {
  function onCalendarLoginSuccess(data) {

    authCalendar(data.code, calendarData => {

      setTimeout(() => {
        forceRenewUserData()
      }, 500)
    })
  }

  const calendarLogin = useGoogleLogin({
    onSuccess: codeResponse => onCalendarLoginSuccess(codeResponse),
    flow: 'auth-code',
    scope: "openid profile email https://www.googleapis.com/auth/calendar.readonly"
  });

  return <button onClick={calendarLogin} style={{ paddingLeft: 25, paddingRight: 25, paddingTop: 2, ...style }}>
    <img src={GoogleCalendarIcon} height="18" style={{ position: 'relative', left: -3, top: 3 }} /> Connect Google Calendar
  </button>


}

function SettingsTabDriveIntegration({ driveIsIntegrated, forceRenewUserData, email_google_drive }) {
  function onDriveLoginSuccess(data) {

    authDrive(data.code, calendarData => {

      setTimeout(() => {
        forceRenewUserData()
      }, 500)
    })
  }

  const driveLogin = useGoogleLogin({
    onSuccess: codeResponse => onDriveLoginSuccess(codeResponse),
    flow: 'auth-code',
    scope: "openid profile email https://www.googleapis.com/auth/drive"
  });

  return <div className="email-notifications-switch">
    <span style={{ marginTop: 2 }}>
      <img src={GoogleDriveIcon} width="18" /> <span className="integration-name">Google Drive™</span> {driveIsIntegrated ? connectedToEmailBadge(email_google_drive) : ''}
      <span className="gray-text">A new document will be saved in your folder after each call ends</span>
    </span>
    <button className={driveIsIntegrated && 'connected'} style={{}} onClick={driveIsIntegrated ? disconnectGoogleDrive : driveLogin}>
      {driveIsIntegrated ? "Disconnect" : "Connect"}
    </button>
  </div>


}

function HubspotSalesForceNoCalendarConnectedPopup({ integrationName, setShowHubspotCalendarRequiredPopup, calendarIsIntegrated, forceRenewUserData, email_google_calendar }) {
  return <>
    <div className="transcript-info-popup-container have-you-installed-slack">
      <div className="popup-background" onClick={() => setShowHubspotCalendarRequiredPopup(false)} style={{ background: 'rgba(0,0,0,.5)' }}></div>
      <div className="popup-content">
        <div className="close-icon" onClick={() => setShowHubspotCalendarRequiredPopup(false)}>
          <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.1946 0.805369C15.6394 1.25016 15.6394 1.97131 15.1946 2.41611L9.61074 8L15.1946 13.5839C15.6394 14.0287 15.6394 14.7498 15.1946 15.1946C14.7498 15.6394 14.0287 15.6394 13.5839 15.1946L8 9.61074L2.41611 15.1946C1.97132 15.6394 1.25016 15.6394 0.805369 15.1946C0.360576 14.7498 0.360576 14.0287 0.805369 13.5839L6.38926 8L0.80537 2.41611C0.360577 1.97132 0.360576 1.25016 0.805369 0.805369C1.25016 0.360576 1.97131 0.360576 2.41611 0.805369L8 6.38926L13.5839 0.80537C14.0287 0.360577 14.7498 0.360576 15.1946 0.805369Z" fill="white" />
          </svg>
        </div>

        <h1 style={{ fontSize: 24 }}> <img src={GoogleCalendarIcon} height="28" style={{ position: 'relative', left: -3, top: 3 }} /> Google Calendar is not connected</h1>

        <p className="white-color-nested">
          Google Calendar is required for automatic contact detection in {integrationName} integration:
          <ul style={{ paddingLeft: 53 }}>
            <li>First Google Calendar event attendee of Google Meet meeting, whose email exists as {integrationName} contact, is associated with created {integrationName} meeting.</li>
            <li>If contact could not be detected, Briefly will send message in Slack asking which contact to associate meeting with (if Slack is connected to Briefly account)</li>
          </ul>
        </p>


        <GoogleOAuthProvider clientId="576404817524-ss93f3dua36hvaum9oi4vqrmu8anc6o3.apps.googleusercontent.com">
          <SettingsTabCalendarIntegrationInPopup calendarIsIntegrated={calendarIsIntegrated} forceRenewUserData={forceRenewUserData} email_google_calendar={email_google_calendar} />
        </GoogleOAuthProvider>
      </div>
    </div>
  </>
}

function ManageHubspotSalesforceMode({
  isSalesforce,
  showHubspotCalendarRequiredPopup,
  setShowHubspotCalendarRequiredPopup,
  is_integration_active_google_calendar,
  calendarIsIntegrated,
  forceRenewUserData,
  email_google_calendar,
  is_integration_active_hubspot,
  is_integration_active_salesforce,
  is_growth_opportunities_active_hubspot,
  is_growth_opportunities_active_salesforce,
  is_hubspot_connected,
  is_salesforce_connected,
  slack_integration_is_visible,
  navigate,
  is_briefs_dm,
  userIsPaid,
  hubspot_integration_is_visible,
  salesforce_integration_is_visible,
  salesforce_api_disabled_error,
  onConnectSalesforceClick,
  onConnectHubspotClick,
  onDisconnectSalesforceClick,
  onDisconnectHubspotClick
}) {
  // <div class="email-notifications-switch" style={{ marginTop: 0 }}>
  //                 <span style={{ marginTop: 2 }}>
  //                       Select which feature you want to integrate:
  //                       <span className="gray-text">
  //                         Note that in order to identify Growth opportunities, Briefly will have to add Transcripts to Hubspot.
  //                       </span>
  //                   </span>
  //                   <label class="switch">
  //                     <input type="checkbox" checked={is_subscribed_to_slack_summaries} onClick={e => {
  //                       const checked = e.target.checked

  //                       updateUserSettings({ is_subscribed_to_slack_summaries: checked }, () => {
  //                         forceRenewUserData()

  //                         if(checked) {
  //                           trackEvent("summaries-to-slack-enabled", {
  //                           })
  //                         } else {
  //                           trackEvent("summaries-to-slack-disabled", {
  //                           })
  //                         }
  //                       })

  //                     }}/>
  //                     {/*<span class="slider round"></span>*/}
  //                   </label>
  //               </div>

  const isIntegrationConnected = isSalesforce ? is_salesforce_connected : is_hubspot_connected

  const integrationName = isSalesforce ? 'Salesforce' : 'Hubspot'

  const isIntegrationVisible = isSalesforce ? salesforce_integration_is_visible : hubspot_integration_is_visible

  const isIntegrationActive = isSalesforce ? is_integration_active_salesforce : is_integration_active_hubspot

  const isGrowthOpportunitiesActive = isSalesforce ? is_growth_opportunities_active_salesforce : is_growth_opportunities_active_hubspot

  const setIntegrationIsActive = isSalesforce ? setSalesforceIntegrationIsActive : setHubspotIntegrationIsActive

  const setGrowthOpportunitiesIsActive = isSalesforce ? setSalesforceGrowthOpportunitiesActive : setHubspotGrowthOpportunitiesActive

  const onConnectClick = isSalesforce ? onConnectSalesforceClick : onConnectHubspotClick

  const onDisconnectClick = isSalesforce ? onDisconnectSalesforceClick : onDisconnectHubspotClick

  return <>
    {showHubspotCalendarRequiredPopup && !is_integration_active_google_calendar ? <HubspotSalesForceNoCalendarConnectedPopup {...{ integrationName, setShowHubspotCalendarRequiredPopup, calendarIsIntegrated, forceRenewUserData, email_google_calendar }} /> : ''}
    <div style={{ marginBottom: 10, cursor: 'pointer' }} onClick={() => { navigate('/settings/integrations') }}>
      <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.81749 0.768078C8.42529 0.347484 7.7632 0.33384 7.35401 0.737919L0 8L7.35401 15.2621C7.7632 15.6662 8.42529 15.6525 8.81749 15.2319C9.19657 14.8254 9.18061 14.1903 8.78158 13.8033L2.79747 8L8.78158 2.19671C9.18061 1.80974 9.19657 1.17461 8.81749 0.768078Z" fill="white" />
      </svg>
      <span style={{ fontSize: 14, fontWeight: 500, position: 'relative', left: 8, bottom: 2 }}>Back</span>
    </div>
    <div id="settings-integrations" className="hubspot-salesforce">
      <div style={{
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'space-between'
      }}>
        <h2>{integrationName} integration</h2>
        <div>
          {salesforce_api_disabled_error ? issueDetectedIcon : ''}
          {userIsPaid ? '' : <div style={{ marginLeft: 3, display: 'inline-block', background: '#F97238', padding: '2px 5px', borderRadius: 4, fontSize: 12, fontWeight: 700, marginRight: 10 }}>PRO</div>}
          {isIntegrationConnected ? <button style={{ marginLeft: 15 }} className="connected" onClick={onDisconnectClick}>
            Disconnect
          </button> : ''}
        </div>
      </div>

      {salesforce_api_disabled_error ? <>
        <h6>
          ⚠️ Briefly cannot connect to your Salesforce account due to API access permissions.
        </h6>
        <p style={{ color: 'white', fontSize: 14 }}>
          Follow the steps below to enable permissions and try again:
          <ul style={{ paddingLeft: 20 }}>
            <li>Go to Salesforce <i>Setup</i> from the cog icon on the top right of your account. <LinkWithStaticInfoPopup linkLabel="Where is this?" popupHeader="Go to Salesforce Setup" popupContent={<img src={SalesforceSetupInfo1} />} /></li>
            <li>In Quick Find type <i>Profiles</i> and click on <i>Profiles</i> in the column below. <LinkWithStaticInfoPopup linkLabel="Where is this?" popupHeader="Open Profiles" popupContent={<img src={SalesforceSetupInfo2} />} /></li>
            <li>Find and click on the Profile Name that applies to the User in question.</li>
            <li>Scroll down to Administrative Permissions and find the option for API Enabled. <LinkWithStaticInfoPopup linkLabel="Where is this?" popupHeader="Check API Enabled box" popupContent={<img src={SalesforceSetupInfo3} />} /></li>
          </ul>
          <span style={{ color: '#C6C6C6', fontSize: 12 }}>
            Salesforce API access is included only in Professional, Enterprise, Performance, Unlimited, and Developer plan. It can be purchased as add-on for other plans.
          </span>
        </p>
        <hr style={{ marginTop: 25, marginBottom: 25, background: '#434343' }} />
      </> : ''}

      {!isIntegrationConnected && userIsPaid ? <div className="email-notifications-switch">
        <span style={{ marginTop: 2, fontSize: 16 }}>
          Connect {integrationName} to enable the features below
        </span>
        <button style={{ width: 180 }} onClick={onConnectClick}>Connect {integrationName}</button>
      </div> : ''}

      {/*<div class="email-notifications-switch">
            <span style={{ marginTop: 2, paddingRight: 10 }}>
                Email address used in Slack
            </span>
            <input type="text" style={{ marginLeft: 'auto', maxWidth: 333, marginTop: 0 }} placeholder="Slack email" value={slackEmailBuffer} onChange={e => {
              setSlackEmailBuffer(e.target.value)
            }}/>
        </div>

        <hr style={{marginTop: 30, marginBottom: 30}}/>*/}
      {isIntegrationVisible ? <div style={{ opacity: isIntegrationConnected ? 1 : 0.6, pointerEvents: isIntegrationConnected ? '' : 'none' }}>
        <h6 style={{ fontSize: 16, marginTop: 20 }}>Select the {integrationName} features you would like to activate</h6>
        <p style={{ fontSize: 13 }}>Note that in order to identify Growth opportunities, Briefly will have to add Transcripts to {integrationName}.</p>
        <div style={{ display: 'flex' }}>
          <div className="hubspot-option" style={{
            background: isIntegrationActive ? '#212121' : '#141414',
            borderRadius: 8,
            border: isIntegrationActive ? '2px solid #E44867' : '1px solid #2C2C2C',
            display: 'flex',
            width: '40%',
            padding: 24,
            paddingLeft: 16,
            cursor: 'pointer'
          }} onClick={() => {
            if (!isIntegrationActive && !is_integration_active_google_calendar) {
              setShowHubspotCalendarRequiredPopup(true)
              return
            }
            setIntegrationIsActive(!isIntegrationActive)
          }}>
            <div style={{ display: 'inline-flex', width: 30 }}>
              {isIntegrationActive ?
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1" y="1" width="28" height="28" rx="4" fill="#141414" stroke="#E44867" strokeWidth="2" />
                  <path d="M20.798 10.236C21.4774 9.55356 22.5816 9.55093 23.2639 10.2305V10.2305C23.9464 10.9102 23.9483 12.0147 23.268 12.6968L14.0032 21.986C13.4437 22.547 12.5362 22.5508 11.9719 21.9946L6.7552 16.8526C6.06386 16.1712 6.06097 15.0567 6.74878 14.3717V14.3717C7.42642 13.6968 8.52087 13.6925 9.20372 14.3621L12.6185 17.7108C12.8252 17.9135 13.1571 17.911 13.3613 17.7059C16.2445 14.8097 18.1057 12.9402 20.798 10.236Z" fill="#E44867" />
                </svg>

                :
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="29" height="29" rx="4.5" fill="#212121" stroke="#2C2C2C" />
                </svg>

              }
            </div>
            <div style={{ display: 'inline-block', width: 'calc(100% - 40px)', marginLeft: 10 }}>
              <span>Add AI Summaries and Transcripts to {integrationName}<Tooltip id={"tooltip-hubspot"} place="bottom" content={"hi"} html={`
                      <div style="max-width: 350px; text-align: left; line-height: 18px; letter-spacing: 0.3px;">
                        <b>How does this work?</b><br/>
                        <ul>
                          <li>First Google Calendar event attendee of Google Meet meeting, whose email exists as ` + integrationName + ` contact, is associated with created ` + integrationName + ` meeting.</li>
                          <li>If contact could not be detected, Briefly will send message in Slack asking which contact to associate meeting with (if Slack is connected to Briefly account)</li>
                          <li>If transcript is too long for ` + integrationName + ` meeting notes, only summary is added.</li>
                        </ul>
                        <span style="color: #C6C6C6"> </span>
                      </div>
                    `} style={{ background: '#2c2c2c' }}>
                hello
              </Tooltip><svg html={<>
              </>} data-tooltip-id={"tooltip-hubspot"} style={{ display: 'inline-block', marginLeft: 5 }} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.33099 5.5493C8.70657 5.17371 8.89437 4.723 8.89437 4.19718C8.89437 3.53991 8.65962 2.97653 8.19014 2.50704C7.72066 2.03756 7.15728 1.80282 6.5 1.80282C5.84272 1.80282 5.27934 2.03756 4.80986 2.50704C4.48644 2.83046 4.27442 3.19844 4.1738 3.61098C4.09638 3.92838 4.37048 4.19718 4.69718 4.19718V4.19718C5.02389 4.19718 5.27519 3.91515 5.43788 3.63184C5.49425 3.53368 5.5666 3.44044 5.65493 3.35211C5.89906 3.10798 6.18075 2.98592 6.5 2.98592C6.81925 2.98592 7.10094 3.10798 7.34507 3.35211C7.5892 3.59624 7.71127 3.87793 7.71127 4.19718C7.71127 4.51643 7.5892 4.79812 7.34507 5.04225L6.61268 5.80282C6.14319 6.30986 5.90845 6.87324 5.90845 7.49296V7.49296C5.90845 7.66409 6.04718 7.80282 6.21831 7.80282H6.5C6.8267 7.80282 7.0831 7.53365 7.16565 7.21755C7.26853 6.82354 7.47858 6.45525 7.79578 6.11268L8.33099 5.5493ZM6.5 10.1972C6.8267 10.1972 7.09155 9.93234 7.09155 9.60563V9.60563C7.09155 9.27893 6.8267 9.01408 6.5 9.01408V9.01408C6.1733 9.01408 5.90845 9.27893 5.90845 9.60563V9.60563C5.90845 9.93234 6.1733 10.1972 6.5 10.1972V10.1972ZM2.24648 1.77465C3.42958 0.591549 4.84742 0 6.5 0C8.15258 0 9.56103 0.591549 10.7254 1.77465C11.9085 2.93897 12.5 4.34742 12.5 6C12.5 7.65258 11.9085 9.07042 10.7254 10.2535C9.56103 11.4178 8.15258 12 6.5 12C4.84742 12 3.42958 11.4178 2.24648 10.2535C1.08216 9.07042 0.5 7.65258 0.5 6C0.5 4.34742 1.08216 2.93897 2.24648 1.77465Z" fill="#C6C6C6" />
                </svg></span>
              <br />
              <span style={{ display: 'inline-block', marginTop: 5 }}>Automatically add your call transcripts and AI summaries to {integrationName} meeting associated with contact.</span>
            </div>
          </div>

          <div className="hubspot-option" style={{
            background: isGrowthOpportunitiesActive ? '#212121' : '#141414',
            borderRadius: 8,
            border: isGrowthOpportunitiesActive ? '2px solid #E44867' : '1px solid #2C2C2C',
            display: 'flex',
            width: '40%',
            padding: 24,
            paddingLeft: 16,
            marginLeft: '5%',
            cursor: 'pointer'
          }} onClick={() => {
            if (!isGrowthOpportunitiesActive && !is_integration_active_google_calendar) {
              setShowHubspotCalendarRequiredPopup(true)
              return
            }
            setGrowthOpportunitiesIsActive(!isGrowthOpportunitiesActive)
          }}>
            <div style={{ display: 'inline-flex', width: 30 }}>
              {isGrowthOpportunitiesActive ?
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="1" y="1" width="28" height="28" rx="4" fill="#141414" stroke="#E44867" strokeWidth="2" />
                  <path d="M20.798 10.236C21.4774 9.55356 22.5816 9.55093 23.2639 10.2305V10.2305C23.9464 10.9102 23.9483 12.0147 23.268 12.6968L14.0032 21.986C13.4437 22.547 12.5362 22.5508 11.9719 21.9946L6.7552 16.8526C6.06386 16.1712 6.06097 15.0567 6.74878 14.3717V14.3717C7.42642 13.6968 8.52087 13.6925 9.20372 14.3621L12.6185 17.7108C12.8252 17.9135 13.1571 17.911 13.3613 17.7059C16.2445 14.8097 18.1057 12.9402 20.798 10.236Z" fill="#E44867" />
                </svg>

                :
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="0.5" y="0.5" width="29" height="29" rx="4.5" fill="#212121" stroke="#2C2C2C" />
                </svg>
              }
            </div>
            <div style={{ display: 'inline-block', width: 'calc(100% - 40px)', marginLeft: 10 }}>
              <span>Get Growth Opportunities in Slack</span>
              <br />
              <span style={{ display: 'inline-block', marginTop: 5 }}>Get a notification in Slack every time Briefly finds a new Growth Opportunity after a call</span>
            </div>
          </div>
        </div>
      </div> : userIsPaid ? <>
        <div className="email-notifications-switch" style={{ opacity: isIntegrationConnected ? 1 : 0.6, pointerEvents: isIntegrationConnected ? '' : 'none' }}>
          <span style={{ marginTop: 2 }}>
            Add AI Summaries and Transcripts to {integrationName} <Tooltip id={"tooltip-hubspot"} place="bottom" content={"hi"} html={`
                      <div style="max-width: 350px; text-align: left; line-height: 18px; letter-spacing: 0.3px;">
                        <b>How does this work?</b><br/>
                        <ul>
                          <li>First Google Calendar event attendee of Google Meet meeting, whose email exists as ` + integrationName + ` contact, is associated with created ` + integrationName + ` meeting.</li>
                          <li>If contact could not be detected, Briefly will send message in Slack asking which contact to associate meeting with (if Slack is connected to Briefly account)</li>
                          <li>If transcript is too long for ` + integrationName + ` meeting notes, only summary is added.</li>
                        </ul>
                        <span style="color: #C6C6C6"> </span>
                      </div>
                    `} style={{ background: '#2c2c2c' }}>
              hello
            </Tooltip><svg html={<>
            </>} data-tooltip-id={"tooltip-hubspot"} style={{ display: 'inline-block', marginLeft: 5 }} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M8.33099 5.5493C8.70657 5.17371 8.89437 4.723 8.89437 4.19718C8.89437 3.53991 8.65962 2.97653 8.19014 2.50704C7.72066 2.03756 7.15728 1.80282 6.5 1.80282C5.84272 1.80282 5.27934 2.03756 4.80986 2.50704C4.48644 2.83046 4.27442 3.19844 4.1738 3.61098C4.09638 3.92838 4.37048 4.19718 4.69718 4.19718V4.19718C5.02389 4.19718 5.27519 3.91515 5.43788 3.63184C5.49425 3.53368 5.5666 3.44044 5.65493 3.35211C5.89906 3.10798 6.18075 2.98592 6.5 2.98592C6.81925 2.98592 7.10094 3.10798 7.34507 3.35211C7.5892 3.59624 7.71127 3.87793 7.71127 4.19718C7.71127 4.51643 7.5892 4.79812 7.34507 5.04225L6.61268 5.80282C6.14319 6.30986 5.90845 6.87324 5.90845 7.49296V7.49296C5.90845 7.66409 6.04718 7.80282 6.21831 7.80282H6.5C6.8267 7.80282 7.0831 7.53365 7.16565 7.21755C7.26853 6.82354 7.47858 6.45525 7.79578 6.11268L8.33099 5.5493ZM6.5 10.1972C6.8267 10.1972 7.09155 9.93234 7.09155 9.60563V9.60563C7.09155 9.27893 6.8267 9.01408 6.5 9.01408V9.01408C6.1733 9.01408 5.90845 9.27893 5.90845 9.60563V9.60563C5.90845 9.93234 6.1733 10.1972 6.5 10.1972V10.1972ZM2.24648 1.77465C3.42958 0.591549 4.84742 0 6.5 0C8.15258 0 9.56103 0.591549 10.7254 1.77465C11.9085 2.93897 12.5 4.34742 12.5 6C12.5 7.65258 11.9085 9.07042 10.7254 10.2535C9.56103 11.4178 8.15258 12 6.5 12C4.84742 12 3.42958 11.4178 2.24648 10.2535C1.08216 9.07042 0.5 7.65258 0.5 6C0.5 4.34742 1.08216 2.93897 2.24648 1.77465Z" fill="#C6C6C6" />
            </svg>
            <span className="gray-text">
              Automatically add your call transcripts and AI summaries to {integrationName} meeting associated with contact.
            </span>
          </span>
          <label className="switch">
            <input type="checkbox" checked={isIntegrationActive} onClick={e => {
              const checked = e.target.checked

              if (checked && !is_integration_active_google_calendar) {
                setShowHubspotCalendarRequiredPopup(true)
                return
              }

              setIntegrationIsActive(checked)

            }} />
            <span className="slider round"></span>

          </label>
        </div>
      </> : ''}
      {/*{isIntegrationVisible ? <>

                <div style={{ display: 'flex' }}>
                  <div className="hubspot-option" style={{
                    background: isIntegrationActive ? '#212121' : '#141414',
                    borderRadius: 8,
                    border: isIntegrationActive ? '2px solid #E44867' : '1px solid #2C2C2C',
                    display: 'flex',
                    width: '40%',
                    padding: 24,
                    paddingLeft: 16,
                    cursor: 'pointer'
                  }} onClick={() => {
                    if(!isIntegrationActive && !is_integration_active_google_calendar) {
                      setShowHubspotCalendarRequiredPopup(true)
                      return
                    }
                    setIntegrationIsActive(!isIntegrationActive)
                  }}>
                    <div style={{ display: 'inline-flex', width: 30}}>
                      {isIntegrationActive ?
                        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="15.5" cy="15" r="14" fill="#141414" stroke="#E44867" stroke-width="2"/>
                          <path d="M21.298 10.236C21.9774 9.55356 23.0816 9.55093 23.7639 10.2305C24.4464 10.9102 24.4483 12.0147 23.768 12.6968L14.5032 21.986C13.9437 22.547 13.0362 22.5508 12.4719 21.9946L7.2552 16.8526C6.56386 16.1712 6.56097 15.0567 7.24878 14.3717C7.92642 13.6968 9.02087 13.6925 9.70372 14.3621L13.1185 17.7108C13.3252 17.9135 13.6571 17.911 13.8613 17.7059C16.7445 14.8097 18.6057 12.9402 21.298 10.236Z" fill="#E44867"/>
                        </svg>
                        :
                        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="15.5" cy="15" r="14.5" fill="#212121" stroke="#2C2C2C"/>
                        </svg>
                      }
                    </div>
                    <div style={{ display: 'inline-block', width: 'calc(100% - 40px)', marginLeft: 10}}>
                      <span>Add AI Summaries and Transcripts to {integrationName}<Tooltip id={"tooltip-hubspot"} place="bottom" content={"hi"} html={`
                            <div style="max-width: 350px; text-align: left; line-height: 18px; letter-spacing: 0.3px;">
                              <b>How does this work?</b><br/>
                              <ul>
                                <li>First Google Calendar event attendee of Google Meet meeting, whose email exists as ` + integrationName + ` contact, is associated with created ` + integrationName + ` meeting.</li>
                                <li>If contact could not be detected, Briefly will send message in Slack asking which contact to associate meeting with (if Slack is connected to Briefly account)</li>
                                <li>If transcript is too long for ` + integrationName + ` meeting notes, only summary is added.</li>
                              </ul>
                              <span style="color: #C6C6C6"> </span>
                            </div>
                          `} style={{ background: '#2c2c2c'}}>
                          hello
                        </Tooltip><svg html={<>
                          </>} data-tooltip-id={"tooltip-hubspot"} style={{ display: 'inline-block', marginLeft: 5 }} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.33099 5.5493C8.70657 5.17371 8.89437 4.723 8.89437 4.19718C8.89437 3.53991 8.65962 2.97653 8.19014 2.50704C7.72066 2.03756 7.15728 1.80282 6.5 1.80282C5.84272 1.80282 5.27934 2.03756 4.80986 2.50704C4.48644 2.83046 4.27442 3.19844 4.1738 3.61098C4.09638 3.92838 4.37048 4.19718 4.69718 4.19718V4.19718C5.02389 4.19718 5.27519 3.91515 5.43788 3.63184C5.49425 3.53368 5.5666 3.44044 5.65493 3.35211C5.89906 3.10798 6.18075 2.98592 6.5 2.98592C6.81925 2.98592 7.10094 3.10798 7.34507 3.35211C7.5892 3.59624 7.71127 3.87793 7.71127 4.19718C7.71127 4.51643 7.5892 4.79812 7.34507 5.04225L6.61268 5.80282C6.14319 6.30986 5.90845 6.87324 5.90845 7.49296V7.49296C5.90845 7.66409 6.04718 7.80282 6.21831 7.80282H6.5C6.8267 7.80282 7.0831 7.53365 7.16565 7.21755C7.26853 6.82354 7.47858 6.45525 7.79578 6.11268L8.33099 5.5493ZM6.5 10.1972C6.8267 10.1972 7.09155 9.93234 7.09155 9.60563V9.60563C7.09155 9.27893 6.8267 9.01408 6.5 9.01408V9.01408C6.1733 9.01408 5.90845 9.27893 5.90845 9.60563V9.60563C5.90845 9.93234 6.1733 10.1972 6.5 10.1972V10.1972ZM2.24648 1.77465C3.42958 0.591549 4.84742 0 6.5 0C8.15258 0 9.56103 0.591549 10.7254 1.77465C11.9085 2.93897 12.5 4.34742 12.5 6C12.5 7.65258 11.9085 9.07042 10.7254 10.2535C9.56103 11.4178 8.15258 12 6.5 12C4.84742 12 3.42958 11.4178 2.24648 10.2535C1.08216 9.07042 0.5 7.65258 0.5 6C0.5 4.34742 1.08216 2.93897 2.24648 1.77465Z" fill="#C6C6C6"/>
                        </svg></span>
                      <br/>
                      <span style={{ display: 'inline-block', marginTop: 5 }}>Automatically add your call transcripts and AI summaries to Hubspot meeting associated with contact.</span>
                    </div>
                  </div>

                  <div className="hubspot-option" style={{
                    background: isGrowthOpportunitiesActive ? '#212121' : '#141414',
                    borderRadius: 8,
                    border: isGrowthOpportunitiesActive ? '2px solid #E44867' : '1px solid #2C2C2C',
                    display: 'flex',
                    width: '40%',
                    padding: 24,
                    paddingLeft: 16,
                    marginLeft: '5%',
                    cursor: 'pointer'
                  }} onClick={() => {
                    if(!isGrowthOpportunitiesActive && !is_integration_active_google_calendar) {
                      setShowHubspotCalendarRequiredPopup(true)
                      return
                    }
                    setGrowthOpportunitiesIsActive(!isGrowthOpportunitiesActive)
                  }}>
                    <div style={{ display: 'inline-flex', width: 30}}>
                      {isGrowthOpportunitiesActive ?
                        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="15.5" cy="15" r="14" fill="#141414" stroke="#E44867" stroke-width="2"/>
                          <path d="M21.298 10.236C21.9774 9.55356 23.0816 9.55093 23.7639 10.2305C24.4464 10.9102 24.4483 12.0147 23.768 12.6968L14.5032 21.986C13.9437 22.547 13.0362 22.5508 12.4719 21.9946L7.2552 16.8526C6.56386 16.1712 6.56097 15.0567 7.24878 14.3717C7.92642 13.6968 9.02087 13.6925 9.70372 14.3621L13.1185 17.7108C13.3252 17.9135 13.6571 17.911 13.8613 17.7059C16.7445 14.8097 18.6057 12.9402 21.298 10.236Z" fill="#E44867"/>
                        </svg>
                        :
                        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <circle cx="15.5" cy="15" r="14.5" fill="#212121" stroke="#2C2C2C"/>
                        </svg>
                      }
                    </div>
                    <div style={{ display: 'inline-block', width: 'calc(100% - 40px)', marginLeft: 10}}>
                      <span>Get Growth Opportunities in Slack</span>
                      <br/>
                      <span style={{ display: 'inline-block', marginTop: 5 }}>Get a notification in Slack every time Briefly finds a new Growth Opportunity after a call</span>
                    </div>
                  </div>
                </div>
              </> : <>
                <div class="email-notifications-switch">
                    <span style={{ marginTop: 2 }}>
                        Add AI Summaries and Transcripts to {integrationName} <Tooltip id={"tooltip-hubspot"} place="bottom" content={"hi"} html={`
                            <div style="max-width: 350px; text-align: left; line-height: 18px; letter-spacing: 0.3px;">
                              <b>How does this work?</b><br/>
                              <ul>
                                <li>First Google Calendar event attendee of Google Meet meeting, whose email exists as ` + integrationName + ` contact, is associated with created ` + integrationName + ` meeting.</li>
                                <li>If contact could not be detected, Briefly will send message in Slack asking which contact to associate meeting with (if Slack is connected to Briefly account)</li>
                                <li>If transcript is too long for ` + integrationName + ` meeting notes, only summary is added.</li>
                              </ul>
                              <span style="color: #C6C6C6"> </span>
                            </div>
                          `} style={{ background: '#2c2c2c'}}>
                          hello
                        </Tooltip><svg html={<>
                          </>} data-tooltip-id={"tooltip-hubspot"} style={{ display: 'inline-block', marginLeft: 5 }} width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M8.33099 5.5493C8.70657 5.17371 8.89437 4.723 8.89437 4.19718C8.89437 3.53991 8.65962 2.97653 8.19014 2.50704C7.72066 2.03756 7.15728 1.80282 6.5 1.80282C5.84272 1.80282 5.27934 2.03756 4.80986 2.50704C4.48644 2.83046 4.27442 3.19844 4.1738 3.61098C4.09638 3.92838 4.37048 4.19718 4.69718 4.19718V4.19718C5.02389 4.19718 5.27519 3.91515 5.43788 3.63184C5.49425 3.53368 5.5666 3.44044 5.65493 3.35211C5.89906 3.10798 6.18075 2.98592 6.5 2.98592C6.81925 2.98592 7.10094 3.10798 7.34507 3.35211C7.5892 3.59624 7.71127 3.87793 7.71127 4.19718C7.71127 4.51643 7.5892 4.79812 7.34507 5.04225L6.61268 5.80282C6.14319 6.30986 5.90845 6.87324 5.90845 7.49296V7.49296C5.90845 7.66409 6.04718 7.80282 6.21831 7.80282H6.5C6.8267 7.80282 7.0831 7.53365 7.16565 7.21755C7.26853 6.82354 7.47858 6.45525 7.79578 6.11268L8.33099 5.5493ZM6.5 10.1972C6.8267 10.1972 7.09155 9.93234 7.09155 9.60563V9.60563C7.09155 9.27893 6.8267 9.01408 6.5 9.01408V9.01408C6.1733 9.01408 5.90845 9.27893 5.90845 9.60563V9.60563C5.90845 9.93234 6.1733 10.1972 6.5 10.1972V10.1972ZM2.24648 1.77465C3.42958 0.591549 4.84742 0 6.5 0C8.15258 0 9.56103 0.591549 10.7254 1.77465C11.9085 2.93897 12.5 4.34742 12.5 6C12.5 7.65258 11.9085 9.07042 10.7254 10.2535C9.56103 11.4178 8.15258 12 6.5 12C4.84742 12 3.42958 11.4178 2.24648 10.2535C1.08216 9.07042 0.5 7.65258 0.5 6C0.5 4.34742 1.08216 2.93897 2.24648 1.77465Z" fill="#C6C6C6"/>
                        </svg>
                        <span className="gray-text">
                          Automatically add your call transcripts and AI summaries to {integrationName} meeting associated with contact.
                        </span>
                    </span>
                    <label class="switch">
                      <input type="checkbox" checked={isIntegrationActive} onClick={e => {
                        const checked = e.target.checked

                        if(checked && !is_integration_active_google_calendar) {
                          setShowHubspotCalendarRequiredPopup(true)
                          return
                        }

                        setIntegrationIsActive(checked)

                      }}/>
                      <span class="slider round"></span>

                    </label>
                </div>
              </>}*/}

      {userIsPaid ? <>


      </> :
        <>
          <div className="email-notifications-switch">
            <span style={{ marginTop: 2, fontSize: 16 }}>
              Upgrade to a PRO account to use this feature
            </span>
            <button style={{ width: 150 }} onClick={() => navigate('/settings/subscriptions')}>Upgrade to PRO</button>
          </div>
          <hr style={{ marginTop: 25, marginBottom: 25, background: '#434343' }} />

          <h6>📚 Add AI summaries and transcripts to {integrationName}</h6>
          <p>
            Briefly automatically adds AI-generated summaries and call transcripts directly to your {integrationName} contacts. This ensures you have all the crucial information right where you need it.
          </p>

          {isIntegrationVisible ? <>
            <h6>🎯 Get growth opportunities in Slack after each call</h6>
            <p>
              Receive instant notifications in Slack whenever Briefly identifies new growth opportunities following a call. Stay ahead of the curve by being the first to know and act on these insights.
            </p>
          </> : ''}


        </>
      }

      {/*{slack_integration_is_visible ? <>
          <hr style={{marginTop: 30, marginBottom: 30}}/>

          <div class="email-notifications-switch">
              <span style={{ marginTop: 2 }}>
                  Customer profiles
                  <span className="gray-text">
                    Create and manage customers profiles with Briefly in Slack. <a>How does this work?</a>
                  </span>
              </span>
              <label class="switch">
                <input type="checkbox" checked={is_integration_active_briefly_success} onClick={e => {
                  const checked = e.target.checked

                  updateUserSettings(checked ? { is_integration_active_briefly_success: true } : {
                    is_integration_active_briefly_success: false,
                    is_subscribed_to_slack_pre_briefs: false
                  }, () => {
                    if(checked) {
                      trackEvent("customer-profiles-enabled", {
                      })
                    } else {
                      trackEvent("customer-profiles-disabled", {
                      })
                      trackEvent("pre-meeting-briefs-disabled", {
                      })
                    }
                    forceRenewUserData()
                  })

                }}/>
                <span class="slider round"></span>
              </label>
          </div>

          <div class="email-notifications-switch">
              <span style={{ marginTop: 2 }}>
                  Customer Briefs 15 minutes before call
                  <span className="gray-text">
                    Before each call starts, get a bespoke briefing about the person you are about to meet with
                  </span>
              </span>
              <label class="switch">
                <input type="checkbox" checked={is_subscribed_to_slack_pre_briefs} onClick={e => {
                  const checked = e.target.checked

                  updateUserSettings(checked ? {
                    is_integration_active_briefly_success: true,
                    is_subscribed_to_slack_pre_briefs: true
                  } : { is_subscribed_to_slack_pre_briefs: false }, () => {
                    if(checked) {
                      trackEvent("customer-profiles-enabled", {
                      })
                      trackEvent("pre-meeting-briefs-enabled", {
                      })
                    } else {
                      trackEvent("pre-meeting-briefs-disabled", {
                      })
                    }
                    forceRenewUserData()
                  })

                }}/>
                <span class="slider round"></span>
              </label>
          </div>

          <div class="email-notifications-switch">
            <span style={{ marginTop: 2 }}>
              Where would you like to receive the reminder?
            </span>
            <div style={{ marginTop: 15 }}>
              <Radio isChecked={is_briefs_dm} setIsChecked={() => {
                updateUserSettings({
                  is_briefs_dm: true
                }, () => {
                  forceRenewUserData()
                })
              }} label="DM"/>
              <Radio isChecked={!is_briefs_dm} setIsChecked={() => {
                updateUserSettings({
                  is_briefs_dm: false
                }, () => {
                  forceRenewUserData()
                })
              }} label="Private channel"/>
            </div>
          </div>
        </> : ''}*/}

    </div>
  </>
}

function ManageZoomMode({ is_integration_active_google_calendar, email_google_calendar, zoom_integration_status, onZoomIntegrationStatusChange, forceRenewUserData, isZoomIntegrationStatusLoading }) {

  const navigate = useNavigate()

  const botShouldJoinAllCalls = zoom_integration_status === 'ZOOM_INTEGRATION_STATUS_JOIN_ALL_MEETINGS'

  return <>
    <div style={{ marginBottom: 10, cursor: 'pointer' }} onClick={() => { navigate('/settings/integrations') }}>
      <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.81749 0.768078C8.42529 0.347484 7.7632 0.33384 7.35401 0.737919L0 8L7.35401 15.2621C7.7632 15.6662 8.42529 15.6525 8.81749 15.2319C9.19657 14.8254 9.18061 14.1903 8.78158 13.8033L2.79747 8L8.78158 2.19671C9.18061 1.80974 9.19657 1.17461 8.81749 0.768078Z" fill="white" />
      </svg>
      <span style={{ fontSize: 14, fontWeight: 500, position: 'relative', left: 8, bottom: 2 }}>Back</span>
    </div>
    <div id="settings-integrations" className="integrations-manage-zoom">
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <h2 style={{ margin: 0 }}>Zoom integration</h2>
        <a onClick={() => window.open('https://www.brieflyai.com/zoom-documentation', '_blank').open()} style={{ cursor: 'pointer', margin: 0 }}>
          FAQ
        </a>
      </div>
      {/*<p>
            🔗 <b>Easy Connection:</b> Sync your calendar, and you’re ready to go <br/><br/>
            🤖 <b>AI-Powered Attendance:</b> BrieflyBot will capture every detail of your call <br/><br/>
            ⚠️ <b>Give permission when requested:</b> The host will need to allow BrieflyBot to participate and record <br/><br/>
            📑 <b>Instant Summaries:</b> Your call summaries will be available in the Briefly dashboard <br/><br/>
          </p>*/}
      <hr style={{ marginTop: 15, marginBottom: 15, background: '#434343' }} />

      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}>
        <h3>Connect your Calendar</h3>
        <div>
          {is_integration_active_google_calendar ? connectedToEmailBadge(email_google_calendar) : connectedToEmailBadge('Not connected')}
          {is_integration_active_google_calendar ? <a style={{ cursor: 'pointer' }} onClick={disconnectGoogleCalendar}>
            Disconnect
          </a> : ''}
        </div>
      </div>

      <p style={{ marginTop: 0 }}>
        Briefly Bot functions exclusively with meetings scheduled via the calendar account that's linked to your Briefly profile. Read the instruction on <a href="https://www.brieflyai.com/zoom-documentation" target="_blank" style={{ textDecoration: 'underline', color: '#E44867' }} rel="noreferrer">how to correctly setup Zoom calls in Google Calendar</a>.
      </p>

      {is_integration_active_google_calendar ? <>
        <div>
          <h3>How should bot join your calls?</h3>
          {isZoomIntegrationStatusLoading ? <>
            <div style={{ height: 220, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <ThreeDots
                height="20"
                width="20"
                radius="9"
                color="white"
                ariaLabel="three-dots-loading"
                wrapperStyle={{}}
                wrapperClassName=""
                visible={true} />
            </div>
          </> : <>
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <div className="hubspot-option" style={{
                background: !botShouldJoinAllCalls ? '#212121' : '#141414',
                borderRadius: 8,
                border: !botShouldJoinAllCalls ? '2px solid #E44867' : '1px solid #2C2C2C',
                display: 'flex',
                width: 'calc(100% - 40px)',
                padding: 24,
                paddingLeft: 16,
                cursor: 'pointer'
              }} onClick={() => {
                onZoomIntegrationStatusChange('ZOOM_INTEGRATION_STATUS_INVITE_ONLY')
              }}>
                <div style={{ display: 'inline-flex', width: 30 }}>
                  {!botShouldJoinAllCalls ?
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="15" cy="15" r="14" fill="#141414" stroke="#E44867" strokeWidth="2" />
                      <circle cx="15" cy="15" r="5" fill="#E44867" />
                    </svg>

                    :
                    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="15.5" cy="15" r="14.5" fill="#212121" stroke="#2C2C2C" />
                    </svg>
                  }
                </div>
                <div style={{ display: 'inline-block', width: 'calc(100% - 40px)', marginLeft: 10 }}>
                  <span>Invite as Needed</span>
                  <br />
                  <span style={{ display: 'inline-block', marginTop: 5 }}>
                    You decide when Briefly Note-Taker joins by adding <b><i>bot@brieflyai.com</i></b> to meeting invites. Remember, the invite must include a Zoom meeting link.
                  </span>
                </div>
              </div>

              <div className="hubspot-option" style={{
                background: botShouldJoinAllCalls ? '#212121' : '#141414',
                borderRadius: 8,
                border: botShouldJoinAllCalls ? '2px solid #E44867' : '1px solid #2C2C2C',
                display: 'flex',
                width: 'calc(100% - 40px)',
                padding: 24,
                paddingLeft: 16,
                marginTop: '20px',
                cursor: 'pointer'
              }} onClick={() => {
                onZoomIntegrationStatusChange('ZOOM_INTEGRATION_STATUS_JOIN_ALL_MEETINGS')
              }}>
                <div style={{ display: 'inline-flex', width: 30 }}>
                  {botShouldJoinAllCalls ?
                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="15" cy="15" r="14" fill="#141414" stroke="#E44867" strokeWidth="2" />
                      <circle cx="15" cy="15" r="5" fill="#E44867" />
                    </svg>
                    :
                    <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <circle cx="15.5" cy="15" r="14.5" fill="#212121" stroke="#2C2C2C" />
                    </svg>
                  }
                </div>
                <div style={{ display: 'inline-block', width: 'calc(100% - 40px)', marginLeft: 10 }}>
                  <span>Automatic Attendance</span>
                  <br />
                  <span style={{ display: 'inline-block', marginTop: 5 }}>
                    BrieflyBot will attend all meetings listed in your calendar that have a Zoom link.
                    No need for manual invites.
                  </span>
                </div>
              </div>
            </div>
          </>}
        </div>

        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          marginTop: 10
        }}>
          <p style={{ fontSize: 14, marginTop: 0, margin: 0 }}>A summary email will be sent after the call ends as per your settings</p>
          <a onClick={() => navigate('/settings/ai-summary-email')} style={{ cursor: 'pointer' }}>
            Manage AI summary email
          </a>
        </div>
      </> : <>

        <p>Link your calendar to track your Zoom meetings:</p>

        <GoogleOAuthProvider clientId="576404817524-ss93f3dua36hvaum9oi4vqrmu8anc6o3.apps.googleusercontent.com">
          <SettingsTabCalendarIntegrationInPopup calendarIsIntegrated={is_integration_active_google_calendar} forceRenewUserData={forceRenewUserData} email_google_calendar={email_google_calendar} style={{ width: 240 }} />
        </GoogleOAuthProvider>

      </>}

    </div>
  </>
}

const issueDetectedIcon = <div style={{ marginLeft: 3, display: 'inline-block', background: '#8E0000', padding: '3px 6px', borderRadius: 4, fontSize: 12, fontWeight: 700 }}>
  <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ marginRight: 5, position: 'relative', top: 2 }}>
    <path d="M6.5 7.09155C6.8267 7.09155 7.09155 6.8267 7.09155 6.5V4.07746C7.09155 3.75076 6.8267 3.48592 6.5 3.48592V3.48592C6.1733 3.48592 5.90845 3.75076 5.90845 4.07746V6.5C5.90845 6.8267 6.1733 7.09155 6.5 7.09155V7.09155ZM6.5 9.51408C6.8267 9.51408 7.09155 9.24924 7.09155 8.92254V8.89437C7.09155 8.56766 6.8267 8.30282 6.5 8.30282V8.30282C6.1733 8.30282 5.90845 8.56766 5.90845 8.89437V8.92254C5.90845 9.24924 6.1733 9.51408 6.5 9.51408V9.51408ZM2.24648 2.27465C3.42958 1.09155 4.84742 0.5 6.5 0.5C8.15258 0.5 9.56103 1.09155 10.7254 2.27465C11.9085 3.43897 12.5 4.84742 12.5 6.5C12.5 8.15258 11.9085 9.57042 10.7254 10.7535C9.56103 11.9178 8.15258 12.5 6.5 12.5C4.84742 12.5 3.42958 11.9178 2.24648 10.7535C1.08216 9.57042 0.5 8.15258 0.5 6.5C0.5 4.84742 1.08216 3.43897 2.24648 2.27465Z" fill="white" />
  </svg>
  Issue detected
</div>


export function SettingsTabIntegrations({ setShowZoomOnboardingPopup, brieflyIsInstalled, brieflyUserData, isManageSlackMode, isManageHubspotMode, isManageSalesforceMode, setShowSlackTip, isManageZoom }) {

  const navigate = useNavigate()

  const [{ is_subscribed_to_emails, email_google_calendar, email_google_drive, is_integration_active_google_calendar, is_integration_active_google_drive,
    is_integration_active_hubspot, is_growth_opportunities_active_hubspot,
    salesforce_integration_is_visible, is_integration_active_salesforce,
    is_growth_opportunities_active_salesforce,
    is_hubspot_connected, is_salesforce_connected,
    is_subscribed_to_slack_pre_briefs, is_subscribed_to_slack_summaries, is_summaries_dm, is_briefs_dm, is_integration_active_briefly_success, slack_email, email,
    slack_integration_is_visible, hubspot_integration_is_visible, admin_user_subscription_is_active, admin_user, subscription_is_active, slack_workspace_name,
    zoom_integration_status,
    hubspot_redshift_db_name,
    salesforce_redshift_db_name,
    salesforce_api_disabled_error,
    force_local_storage,
    zoom_is_setup
  }, forceRenewUserData] = useUserData()
  const [showHaveYouInstalledSlackAppPopup, setShowHaveYouInstalledSlackAppPopup] = useState(false)
  const [dontShowHaveYouInstalledSlackAgain, setDontShowHaveYouInstalledSlackAgain] = useState(false)

  const [showHubspotCalendarRequiredPopup, setShowHubspotCalendarRequiredPopup] = useState(false)

  const [isZoomIntegrationStatusLoading, setIsZoomIntegrationStatusLoading] = useState(false)

  const [slackEmailBuffer, setSlackEmailBuffer] = useState(slack_email ? slack_email : email)
  const [slackEmailBufferDebounce] = useDebounce(slackEmailBuffer, 1000)

  const userIsPaid = (subscription_is_active || (!!admin_user && admin_user_subscription_is_active))

  const hubspotDomain = hubspot_redshift_db_name ? hubspot_redshift_db_name?.replace('staging-hubspot-', '')?.replace('prod-hubspot-', '')?.replaceAll('-', '.') : ''
  const salesforceDomain = salesforce_redshift_db_name ? salesforce_redshift_db_name?.replace('staging-salesforce-', '')?.replace('prod-salesforce-', '')?.replaceAll('-', '.') : ''

  useEffect(() => {
    if (isManageSlackMode) {
      if (!localStorage.getItem('HAVE_YOU_INSTALLED_SLACK_TIP_CLOSED') && userIsPaid && !slack_workspace_name) {
        setShowHaveYouInstalledSlackAppPopup(true)
      }
    }
  }, [isManageSlackMode])

  useEffect(() => {
    updateUserSettings({
      slack_email: slackEmailBufferDebounce
    }, () => {
      forceRenewUserData()
    })
  }, [slackEmailBufferDebounce])

  const calendarIsIntegrated = is_integration_active_google_calendar
  const driveIsIntegrated = is_integration_active_google_drive

  useEffect(() => {
    const interval = setInterval(() => {
      forceRenewUserData();
    }, 3000);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const isHubspotManagedBySomeoneElse = false
  const isSalesforceManagedBySomeoneElse = false

  const showNoCalendarPopupHubspot = isHubspotManagedBySomeoneElse && !is_integration_active_google_calendar
  const showNoCalendarPopupSalesforce = isSalesforceManagedBySomeoneElse && !is_integration_active_google_calendar

  const onConnectHubspotClick = () => {
    trackEvent('hubspot-connect-clicked', {})
    setTimeout(() => {
      window.location = HUBSPOT_CONNECT_LINK
    }, 500)
  }

  const onConnectSalesforceClick = () => {
    trackEvent('salesforce-connect-clicked', {})
    setTimeout(() => {
      window.location = SALESFORCE_CONNECT_LINK
    }, 500)
  }

  const onDisconnectHubspotClick = () => {
    disconnectHubspot(() => {
      forceRenewUserData()
    })
  }

  const onDisconnectSalesforceClick = () => {
    disconnectSalesforce(() => {
      forceRenewUserData()
    })
  }

  useEffect(() => {
    if (showNoCalendarPopupHubspot || showNoCalendarPopupSalesforce) {
      setShowHubspotCalendarRequiredPopup(true)
    }
  }, [])

  if (isManageSalesforceMode) {
    return <ManageHubspotSalesforceMode isSalesforce={true} {...{
      showHubspotCalendarRequiredPopup,
      setShowHubspotCalendarRequiredPopup,
      is_integration_active_google_calendar,
      calendarIsIntegrated,
      forceRenewUserData,
      email_google_calendar,
      is_integration_active_hubspot,
      is_integration_active_salesforce,
      is_growth_opportunities_active_hubspot,
      is_growth_opportunities_active_salesforce,
      slack_integration_is_visible,
      navigate,
      is_briefs_dm,
      userIsPaid,
      hubspot_integration_is_visible,
      salesforce_integration_is_visible,
      salesforce_api_disabled_error,
      is_hubspot_connected,
      is_salesforce_connected,
      onConnectSalesforceClick,
      onConnectHubspotClick,
      onDisconnectHubspotClick,
      onDisconnectSalesforceClick
    }} />
  }

  if (isManageHubspotMode) {
    return <ManageHubspotSalesforceMode isSalesforce={false} {...{
      showHubspotCalendarRequiredPopup,
      setShowHubspotCalendarRequiredPopup,
      is_integration_active_google_calendar,
      calendarIsIntegrated,
      forceRenewUserData,
      email_google_calendar,
      is_integration_active_hubspot,
      is_integration_active_salesforce,
      is_growth_opportunities_active_hubspot,
      is_growth_opportunities_active_salesforce,
      slack_integration_is_visible,
      navigate,
      is_briefs_dm,
      userIsPaid,
      hubspot_integration_is_visible,
      is_hubspot_connected,
      is_salesforce_connected,
      onConnectSalesforceClick,
      onConnectHubspotClick,
      onDisconnectHubspotClick,
      onDisconnectSalesforceClick
    }} />
  }

  if (isManageZoom) {
    return <ManageZoomMode {...{
      is_integration_active_google_calendar,
      email_google_calendar,
      zoom_integration_status,
      onZoomIntegrationStatusChange: newStatus => {
        setIsZoomIntegrationStatusLoading(true)
        updateUserSettings({ zoom_integration_status: newStatus }, () => {
          forceRenewUserData()
          setTimeout(() => {
            setIsZoomIntegrationStatusLoading(false)
          }, 500)
        })
      },
      forceRenewUserData,
      isZoomIntegrationStatusLoading
    }} />
  }

  if (isManageSlackMode) {
    return <>
      {showHaveYouInstalledSlackAppPopup ? <>
        <div className="transcript-info-popup-container have-you-installed-slack">
          <div className="popup-background" onClick={() => setShowHaveYouInstalledSlackAppPopup(false)} style={{ background: 'rgba(0,0,0,.5)' }}></div>
          <div className="popup-content">
            <div className="close-icon" onClick={() => setShowHaveYouInstalledSlackAppPopup(false)}>
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.1946 0.805369C15.6394 1.25016 15.6394 1.97131 15.1946 2.41611L9.61074 8L15.1946 13.5839C15.6394 14.0287 15.6394 14.7498 15.1946 15.1946C14.7498 15.6394 14.0287 15.6394 13.5839 15.1946L8 9.61074L2.41611 15.1946C1.97132 15.6394 1.25016 15.6394 0.805369 15.1946C0.360576 14.7498 0.360576 14.0287 0.805369 13.5839L6.38926 8L0.80537 2.41611C0.360577 1.97132 0.360576 1.25016 0.805369 0.805369C1.25016 0.360576 1.97131 0.360576 2.41611 0.805369L8 6.38926L13.5839 0.80537C14.0287 0.360577 14.7498 0.360576 15.1946 0.805369Z" fill="white" />
              </svg>
            </div>

            <h1>Have you installed Briefly Slack App?</h1>

            <p>
              You must add Briefly to Slack in order to use these features
            </p>

            <Checkbox isChecked={dontShowHaveYouInstalledSlackAgain} label="Don’t show this message again" setIsChecked={isChecked => {
              if (isChecked) {
                localStorage.setItem('HAVE_YOU_INSTALLED_SLACK_TIP_CLOSED', "1")
                setDontShowHaveYouInstalledSlackAgain(true)
              } else {
                localStorage.removeItem('HAVE_YOU_INSTALLED_SLACK_TIP_CLOSED')
                setDontShowHaveYouInstalledSlackAgain(false)
              }
            }} />

            <br />

            <a onClick={() => {
              trackEvent("briefly-add-to-slack-button-clicked", {
              })
            }} target="_blank" style={{ marginRight: '30px', position: 'relative', top: 14, display: 'inline-block' }} href={`https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&scope=app_mentions:read,channels:history,channels:join,channels:manage,channels:read,chat:write,chat:write.customize,chat:write.public,files:write,groups:read,groups:write,im:history,im:read,im:write,links:write,mpim:history,mpim:read,mpim:write,pins:write,reminders:read,reminders:write,users:read,users:read.email,groups:history&user_scope=`} rel="noreferrer">
              <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
            </a>
            <button onClick={() => {
              setShowHaveYouInstalledSlackAppPopup(false)
            }} style={{ paddingLeft: 25, paddingRight: 25 }}>
              Manage Slack integration
            </button>
          </div>
        </div>
      </> : ''}

      <div style={{ marginBottom: 10, cursor: 'pointer' }} onClick={() => { navigate('/settings/integrations') }}>
        <svg width="10" height="16" viewBox="0 0 10 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.81749 0.768078C8.42529 0.347484 7.7632 0.33384 7.35401 0.737919L0 8L7.35401 15.2621C7.7632 15.6662 8.42529 15.6525 8.81749 15.2319C9.19657 14.8254 9.18061 14.1903 8.78158 13.8033L2.79747 8L8.78158 2.19671C9.18061 1.80974 9.19657 1.17461 8.81749 0.768078Z" fill="white" />
        </svg>
        <span style={{ fontSize: 14, fontWeight: 500, position: 'relative', left: 8, bottom: 2 }}>Back</span>
      </div>
      <div id="settings-integrations">
        <div style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
          <h2>Slack integration{userIsPaid ? ' settings' : ''}</h2>
          {slack_workspace_name ? connectedToEmailBadge(slack_workspace_name) : <a onClick={() => {
            trackEvent("briefly-add-to-slack-button-clicked", {
            })
          }} target="_blank" style={{ transform: 'scale(0.85)', marginRight: '-14px', display: userIsPaid ? '' : 'none' }} href={`https://slack.com/oauth/v2/authorize?client_id=${SLACK_CLIENT_ID}&scope=app_mentions:read,channels:history,channels:join,channels:manage,channels:read,chat:write,chat:write.customize,chat:write.public,files:write,groups:read,groups:write,im:history,im:read,im:write,links:write,mpim:history,mpim:read,mpim:write,pins:write,reminders:read,reminders:write,users:read,users:read.email,groups:history&user_scope=`} rel="noreferrer">
            <img alt="Add to Slack" height="40" width="139" src="https://platform.slack-edge.com/img/add_to_slack.png" srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x" />
          </a>}
        </div>

        {/*<div class="email-notifications-switch">
            <span style={{ marginTop: 2, paddingRight: 10 }}>
                Email address used in Slack
            </span>
            <input type="text" style={{ marginLeft: 'auto', maxWidth: 333, marginTop: 0 }} placeholder="Slack email" value={slackEmailBuffer} onChange={e => {
              setSlackEmailBuffer(e.target.value)
            }}/>
        </div>

        <hr style={{marginTop: 30, marginBottom: 30}}/>*/}

        {userIsPaid ? <>
          <div className="email-notifications-switch" style={{ marginTop: 20 }}>
            <span style={{ marginTop: 2 }}>
              Post-meeting Summaries
              <span className="gray-text">
                After each call receive your AI summary directly into Slack
              </span>
            </span>
            <label className="switch">
              <input type="checkbox" checked={is_subscribed_to_slack_summaries} onClick={e => {
                const checked = e.target.checked

                updateUserSettings({ is_subscribed_to_slack_summaries: checked }, () => {
                  forceRenewUserData()

                  if (checked) {
                    trackEvent("summaries-to-slack-enabled", {
                    })
                  } else {
                    trackEvent("summaries-to-slack-disabled", {
                    })
                  }
                })

              }} />
              <span className="slider round"></span>
            </label>
          </div>

          <div className="email-notifications-switch">
            <span style={{ marginTop: 2 }}>
              Where would you like to receive this?
            </span>
            <div style={{ marginTop: 15 }}>
              <Radio isChecked={is_summaries_dm} setIsChecked={() => {
                updateUserSettings({
                  is_summaries_dm: true
                }, () => {
                  forceRenewUserData()
                })
              }} label="DM" />
              <Radio isChecked={!is_summaries_dm} setIsChecked={() => {
                updateUserSettings({
                  is_summaries_dm: false
                }, () => {
                  forceRenewUserData()
                })
              }} label="Private channel" />
            </div>
          </div> </> :
          <div className="email-notifications-switch">
            <span style={{ marginTop: 2 }}>
              Slack integration is a PRO feature only
              <span className="gray-text">
                Upgrade to a PRO account to use this feature
              </span>
            </span>
            <button style={{ width: 150 }} onClick={() => navigate('/settings/subscriptions')}>Upgrade to PRO</button>
          </div>
        }

        {slack_integration_is_visible ? <>
          <hr style={{ marginTop: 30, marginBottom: 30 }} />

          <div className="email-notifications-switch">
            <span style={{ marginTop: 2 }}>
              Customer profiles
              <span className="gray-text">
                Create and manage customers profiles with Briefly in Slack. <a>How does this work?</a>
              </span>
            </span>
            <label className="switch">
              <input type="checkbox" checked={is_integration_active_briefly_success} onClick={e => {
                const checked = e.target.checked

                updateUserSettings(checked ? { is_integration_active_briefly_success: true } : {
                  is_integration_active_briefly_success: false,
                  is_subscribed_to_slack_pre_briefs: false
                }, () => {
                  if (checked) {
                    trackEvent("customer-profiles-enabled", {
                    })
                  } else {
                    trackEvent("customer-profiles-disabled", {
                    })
                    trackEvent("pre-meeting-briefs-disabled", {
                    })
                  }
                  forceRenewUserData()
                })

              }} />
              <span className="slider round"></span>
            </label>
          </div>

          <div className="email-notifications-switch">
            <span style={{ marginTop: 2 }}>
              Customer Briefs 15 minutes before call
              <span className="gray-text">
                Before each call starts, get a bespoke briefing about the person you are about to meet with
              </span>
            </span>
            <label className="switch">
              <input type="checkbox" checked={is_subscribed_to_slack_pre_briefs} onClick={e => {
                const checked = e.target.checked

                updateUserSettings(checked ? {
                  is_integration_active_briefly_success: true,
                  is_subscribed_to_slack_pre_briefs: true
                } : { is_subscribed_to_slack_pre_briefs: false }, () => {
                  if (checked) {
                    trackEvent("customer-profiles-enabled", {
                    })
                    trackEvent("pre-meeting-briefs-enabled", {
                    })
                  } else {
                    trackEvent("pre-meeting-briefs-disabled", {
                    })
                  }
                  forceRenewUserData()
                })

              }} />
              <span className="slider round"></span>
            </label>
          </div>

          <div className="email-notifications-switch">
            <span style={{ marginTop: 2 }}>
              Where would you like to receive the reminder?
            </span>
            <div style={{ marginTop: 15 }}>
              <Radio isChecked={is_briefs_dm} setIsChecked={() => {
                updateUserSettings({
                  is_briefs_dm: true
                }, () => {
                  forceRenewUserData()
                })
              }} label="DM" />
              <Radio isChecked={!is_briefs_dm} setIsChecked={() => {
                updateUserSettings({
                  is_briefs_dm: false
                }, () => {
                  forceRenewUserData()
                })
              }} label="Private channel" />
            </div>
          </div>
        </> : ''}

      </div>
    </>
  }

  // alert([brieflyUserData.profile.email, brieflyUserData.team_hubspot_admin])

  return (
    <div id="settings-integrations">
      <h2>Integrations</h2>
      {showNoCalendarPopupHubspot && showHubspotCalendarRequiredPopup ? <HubspotSalesForceNoCalendarConnectedPopup {...{ integrationName: 'Hubspot', setShowHubspotCalendarRequiredPopup, calendarIsIntegrated, forceRenewUserData, email_google_calendar }} /> : ''}
      {showNoCalendarPopupSalesforce && showHubspotCalendarRequiredPopup && !showNoCalendarPopupHubspot ? <HubspotSalesForceNoCalendarConnectedPopup {...{ integrationName: 'Salesforce', setShowHubspotCalendarRequiredPopup, calendarIsIntegrated, forceRenewUserData, email_google_calendar }} /> : ''}
      {<>
        <div className="email-notifications-switch">
          <span style={{ marginTop: 2 }}>
            <img src={GoogleMeetIcon} width="18" /> <span className="integration-name">Google Meet</span> {brieflyIsInstalled ? connectedToEmailBadge(brieflyUserData?.profile?.email) : ''}
            <span className="gray-text">Download the Chrome Extension to use Briefly in Google Meet</span>
          </span>
          {brieflyIsInstalled ? <p style={{ textAlign: 'right' }}>Chrome Extension Installed</p> : <button onClick={e => {
            window.open('https://chrome.google.com/webstore/detail/briefly-ai-meeting-summar/bjmgcelbpkgmofiogkmleblcmecflldk', '_blank')
          }}>

            {/* If userData.email_zoom exists then show disconnect, else show connect */}
            Download extension
          </button>}
        </div>
        {<hr style={{ marginTop: 30, marginBottom: 30 }} />}
      </>}
      {true ? <>
        <div className="email-notifications-switch">
          <span style={{ marginTop: 2 }}>
            <img src={ZoomIcon} width="18" /> <span className="integration-name">Zoom</span>
            <span className="gray-text">Manage Briefly for your Zoom calls</span>
          </span>
          {zoom_is_setup ? <button onClick={() => {
            navigate('/settings/integrations/zoom')
          }}>
            Manage
          </button> : <button onClick={() => {
            setShowZoomOnboardingPopup(true)
          }}>
            Setup
          </button>}
        </div>
        <hr style={{ marginTop: 30, marginBottom: 30 }} />
      </> : ''}
      <div className="email-notifications-switch">
        <span style={{ marginTop: 2 }}>
          <img src={SlackIcon} width="18" /> <span className="integration-name">Slack {!userIsPaid ? <div style={{ marginLeft: 3, display: 'inline-block', background: '#F97238', padding: '2px 5px', borderRadius: 4, fontSize: 12, fontWeight: 700 }}>PRO</div> : (slack_workspace_name ? connectedToEmailBadge(slack_workspace_name) : '')}</span>
          <span className="gray-text">Connect to receive AI summaries{slack_integration_is_visible ? ' and pre call briefings' : ''}</span>
        </span>
        <div>
          <button onClick={() => {
            navigate('/settings/integrations/slack')
          }}>
            Manage
          </button>
          <button onClick={() => {
            setShowSlackTip(true)
          }} style={{ marginLeft: 15 }}>
            About
          </button>
        </div>
      </div> <hr style={{ marginTop: 30, marginBottom: 30 }} />
      <GoogleOAuthProvider clientId="576404817524-ss93f3dua36hvaum9oi4vqrmu8anc6o3.apps.googleusercontent.com">
        <SettingsTabCalendarIntegration calendarIsIntegrated={calendarIsIntegrated} forceRenewUserData={forceRenewUserData} email_google_calendar={email_google_calendar} />
      </GoogleOAuthProvider>
      {/*<hr style={{ marginTop: 30, marginBottom: 30 }}/>
      <GoogleOAuthProvider clientId="576404817524-lq2fgg2dhiup0p6ius7ftimi2h954rj5.apps.googleusercontent.com">
        <SettingsTabDriveIntegration driveIsIntegrated={driveIsIntegrated} forceRenewUserData={forceRenewUserData} email_google_drive={email_google_drive} />
      </GoogleOAuthProvider>*/}
      {!force_local_storage ? <>
        <hr style={{ marginTop: 30, marginBottom: 30 }} />
        <div className="email-notifications-switch">
          <span style={{ marginTop: 2 }}>
            <img src={HubspotIcon} width="18" /> <span className="integration-name">Hubspot</span> {!userIsPaid ? <div style={{ marginLeft: 3, display: 'inline-block', background: '#F97238', padding: '2px 5px', borderRadius: 4, fontSize: 12, fontWeight: 700 }}>PRO</div> : (is_hubspot_connected || isHubspotManagedBySomeoneElse ? connectedToEmailBadge(isHubspotManagedBySomeoneElse ? 'Connected and managed by ' + 'team_hubspot_admin' : hubspotDomain) : '')}
            <span className="gray-text">Connect to save summaries and transcripts in Hubspot<br />Requires Google Calendar integration</span>
          </span>
          {isHubspotManagedBySomeoneElse ? <div /> : <div>
            <button onClick={() => {
              navigate('/settings/integrations/hubspot')
            }}>
              Manage
            </button>

          </div>}
        </div>
      </> : ''}
      {!force_local_storage ? <>
        <hr style={{ marginTop: 30, marginBottom: 30 }} />
        <div className="email-notifications-switch">
          <span style={{ marginTop: 2 }}>
            <img src={SalesforceIcon} width="18" /> <span className="integration-name">Salesforce</span> {salesforce_api_disabled_error ? issueDetectedIcon : ''} {!userIsPaid ? <div style={{ marginLeft: 3, display: 'inline-block', background: '#F97238', padding: '2px 5px', borderRadius: 4, fontSize: 12, fontWeight: 700 }}>PRO</div> : (is_salesforce_connected || isSalesforceManagedBySomeoneElse ? connectedToEmailBadge(isSalesforceManagedBySomeoneElse ? 'Connected and managed by ' + 'team_salesforce_admin' : salesforceDomain) : '')}
            <span className="gray-text">Connect to save summaries and transcripts in Salesforce</span>
          </span>
          {isSalesforceManagedBySomeoneElse ? <div /> : <div>
            <button onClick={() => {
              navigate('/settings/integrations/salesforce')
            }}>
              Manage
            </button>

          </div>}
        </div>
      </> : ''
      }
    </div>
  )
}

export default SettingsTabIntegrations
