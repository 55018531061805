import { useState, useRef, useEffect } from 'react'
import { useBrieflyStorage } from '../hooks/useBrieflyStorage'
import { useBackendStoragePromptsList, useBackendStorageMyPrompts } from '../hooks/useBackendStorage'
import { useDataFromEndpoint } from '../hooks/useDataFromEndpoint'
import {
  getUserAccessToken, getTranscriptionAsText, generatePromptOutput, copyToClipboard, trackEvent, highlightText, getContextStrings,
  checkIfStandardSummaryIsEligible,
  checkIfCustomSummaryIsEligible,
  getNumberOfLines
} from '../../utils/genericUtils'
import { ThreeDots } from 'react-loader-spinner'
import { LoadingSvg } from '../../images/LoadingSvg'
import { Dropdown } from '../controls/Dropdown'
import { PromptTemplateList } from '../controls/PromptTemplateList'
import { Checkbox } from '../controls/Checkbox'
import { IconWithTimeoutReplaceOnClick } from '../../utils/IconWithTimeoutReplaceOnClick'
import { useUserData } from '../hooks/useUserData';
import { useWindowSize } from '../hooks/useWindowSize';

function generateEmptyPrompt() {
  return [{
    label: 'Empty prompt',
    prompt: '',
    isActive: true
  }]
}

export default function MeetingTabOutput({ meetingData, version, isGpt4, searchWords, setShowOutOfCreditsPopup }) {

  const [{ subscription_is_active, admin_user_subscription_is_active, subscription_plan }, forceRenewUserData] = useUserData()

  const subscriptionIsActive = subscription_is_active || admin_user_subscription_is_active || subscription_plan === 'STANDARD_GIVEN_FOR_FREE'

  const [isBrieflyTemplatesSelected, setIsBrieflyTemplatesSelected] = useState(false)

  const DROPDOWN_ITEMS = [
    {
      label: 'Briefly templates',
      onSelect: () => setIsBrieflyTemplatesSelected(true)
    },
    {
      label: 'My saved templates',
      onSelect: () => setIsBrieflyTemplatesSelected(false)
    }
  ]
  const currentDropdownItem = DROPDOWN_ITEMS[isBrieflyTemplatesSelected ? 0 : 1]

  const [localStoragePromptsList, setLocalStoragePromptsList, localStoragePromptsListIsLoading] = useBrieflyStorage({
    storageKey: meetingData.meetingId + '-customised-prompts',
    defaultValue: generateEmptyPrompt(),
    processOnInitialLoad: list => list.length > 0 ? list.map(x => ({ ...x, isLoading: false })) : generateEmptyPrompt()
  })

  const [localStorageMyPrompts, setLocalStorageMyPrompts, localStorageMyPromptsIsLoading] = useBrieflyStorage({
    storageKey: 'briefly-my-prompts',
    defaultValue: [],
    delay: true,
    processOnInitialLoad: list => list.map(x => typeof x === 'string' ? { prompt: x, label: x } : x)
  })

  const [promptsList, setPromptsList, promptsListIsLoading] = useBackendStoragePromptsList({
    defaultValue: generateEmptyPrompt(),
    transcriptId: meetingData.meetingId,
    processOnInitialLoad: list => list.length > 0 ? list.map(x => ({ ...x, isLoading: false })) : generateEmptyPrompt()
  })

  const [myPrompts, setMyPrompts, myPromptsIsLoading] = useBackendStorageMyPrompts({
    defaultValue: [],
    delay: true,
    processOnInitialLoad: list => list.map(x => typeof x === 'string' ? { prompt: x, label: x } : x)
  })

  const isLoading = localStoragePromptsListIsLoading || localStorageMyPromptsIsLoading || promptsListIsLoading || myPromptsIsLoading

  const isLoaded = !isLoading

  useEffect(() => {
    if (isLoaded) {
      // Migrate local storage my prompts to backend

      if (localStorageMyPrompts.length > 0) {
        setMyPrompts(myPrompts.concat(localStorageMyPrompts))

        trackEvent("starting-my-prompts-local-to-backend-migration", {
          "localStorageMyPrompts": localStorageMyPrompts
        })

        // Mark as migrated
        setLocalStorageMyPrompts([])

        window.location.reload()
      }

      // TODO Migrate local storage transcript prompts to backend?
    }
  }, [isLoaded])

  const [brieflyRecommendedPrompts, brieflyRecommendedPromptsIsLoading, brieflyRecommendedPromptsError] = useDataFromEndpoint(
    '/api/briefly/briefly_recommended_prompts'
  )

  const [generatedResultBuffer, setGeneratedResultBuffer] = useState(null)

  const [isLeftCollapseOpen, setIsLeftCollapseOpen] = useState(false)
  const [isRightCollapseOpen, setIsRightCollapseOpen] = useState(true)

  const [dropdownIsOpen, setDropdownIsOpen] = useState(false)

  const [isMyPromptNamingMode, setIsMyPromptNamingMode] = useState(false)
  const [promptNameValue, setPromptNameValue] = useState("")

  const windowSize = useWindowSize()

  useEffect(() => {
    if (generatedResultBuffer) {
      const { result, originalPrompt, error } = generatedResultBuffer


      if (error) {
        setPromptsList(prev => (prev || promptsList).map(x => x.prompt == originalPrompt.prompt ? { ...x, output: null, isLoading: false, error: true } : x))
      } else {
        setPromptsList(prev => (prev || promptsList).map(x => x.prompt == originalPrompt.prompt ? { ...x, output: result, isLoading: false, error: false, originalPrompt: originalPrompt.prompt } : x))
      }

    }
  }, [generatedResultBuffer])

  const currentPrompt = promptsList?.find(x => x.isActive) || promptsList?.[0]

  const newOutputBtnIsActive = !!currentPrompt?.prompt

  useEffect(() => {
    if (searchWords) {
      setTimeout(() => {
        if (!promptsListIsLoading) {
          var promptWithSearchResult = promptsList.find(x => getContextStrings(x.output, searchWords, 1).length > 0)
          setCurrentPrompt(promptWithSearchResult)
        }
      }, 100)
    }
  }, [searchWords])

  function setCurrentPrompt(prompt, newPromptList = null) {
    setPromptsList(
      (newPromptList ? newPromptList : promptsList).map(x => x == prompt ? {
        ...x,
        isActive: true
      } : {
        ...x,
        isActive: false
      })
    )
  }

  const promptTextarea = useRef(null)

  function updateCurrentPrompt(newValue) {

    const isAutoGeneratedLabel = currentPrompt.label === 'Empty prompt' || currentPrompt.prompt.startsWith(currentPrompt.label.replace("...", ""))

    const newPrompt = {
      prompt: newValue,
      label: !isAutoGeneratedLabel ? currentPrompt.label : !newValue ? 'Empty prompt' : newValue.slice(0, 25) + (newValue.length > 25 ? '...' : ''),
      isActive: true,
    }

    if (currentPrompt.output) {
      newPrompt.output = currentPrompt.output
      newPrompt.originalPrompt = currentPrompt.originalPrompt
    }

    setPromptsList(promptsList.map(item => item == currentPrompt ? newPrompt : { ...item, isActive: false }))
  }

  function createNewPrompt() {
    trackEvent("briefly-create-create-new-prompt", {
      "total-prompts-open-before-create": promptsList.length
    })

    if (promptsList.find(x => x.prompt === '')) {
      setCurrentPrompt(promptsList.find(x => x.prompt === ''))
      return;
    }
    const newPrompt = {
      label: 'Empty prompt',
      prompt: '',
      isActive: true
    }
    setPromptsList(promptsList.map(x => ({ ...x, isActive: false })).concat([newPrompt]))
  }

  function deleteCurrentPrompt() {
    setPromptsList(promptsList.filter(x => x != currentPrompt))
  }

  function generateOutput() {

    checkIfCustomSummaryIsEligible(getTranscriptionAsText(meetingData), currentPrompt.prompt, isEligible => {
      if (isEligible) {
        trackEvent("briefly-create-generate-output", {
          "prompt": currentPrompt.prompt
        })

        const promptBeingProcessedLoadingState = {
          ...currentPrompt,
          isLoading: true,
          error: false
        }

        setPromptsList(promptsList.map(item => item == currentPrompt ? promptBeingProcessedLoadingState : item))

        getUserAccessToken(({ access }) => {
          generatePromptOutput(meetingData, promptBeingProcessedLoadingState, access, version, isGpt4).then(response => {
            forceRenewUserData()
            setGeneratedResultBuffer({
              originalPrompt: promptBeingProcessedLoadingState,
              result: response.result,
              error: response.error
            })
          })
        })
      } else {
        setShowOutOfCreditsPopup(true)
      }
    })


  }

  if (isLoading) {
    return ''
  }

  const generateButtonIsActive = currentPrompt?.prompt && (!currentPrompt?.output || (currentPrompt?.originalPrompt !== currentPrompt?.prompt))

  const brieflyRecommendedPromptsInBottom = brieflyRecommendedPrompts?.prompts?.filter(p => p.show_in_bottom_panel)

  const isTheLastPrompt = promptsList.length <= 1

  const isFirstOutputGenerated = promptsList.length > 1 || !!currentPrompt?.output

  return (
    <div id="meeting-tab-output-container" style={{ width: '100%', maxWidth: '100%' }}>
      {/*<div id="output-header">
        <h4>Turn your meeting transcripts into deliverables</h4>


      </div>*/}
      <div id="meeting-tab-output" style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div id="prompts-list" style={{ display: isFirstOutputGenerated ? '' : 'none', width: isLeftCollapseOpen ? 168 : 45, minWidth: isLeftCollapseOpen ? 168 : 45, padding: 16, borderRight: '1px solid #212121' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {isLeftCollapseOpen ? <h3 style={{ margin: 0, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "18px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>AI Outputs</h3> : ''}
            <button id="hide-past-calls" style={{ width: 50, marginTop: 0 }} onClick={() => setIsLeftCollapseOpen(!isLeftCollapseOpen)}>
              {isLeftCollapseOpen ? 'Hide' : ''}
              <svg style={{ transform: isLeftCollapseOpen ? '' : 'rotate(180deg)', left: isLeftCollapseOpen ? 5 : 0 }} width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.90875 8.11596C8.71265 8.32626 8.3816 8.33308 8.177 8.13104L4.5 4.5L8.177 0.868959C8.3816 0.66692 8.71265 0.673742 8.90875 0.884039C9.09829 1.0873 9.0903 1.40487 8.89079 1.59835L5.89873 4.5L8.89079 7.40165C9.0903 7.59513 9.09829 7.9127 8.90875 8.11596Z" fill="#C6C6C6" />
                <path d="M5.90875 8.11596C5.71265 8.32626 5.3816 8.33308 5.177 8.13104L1.5 4.5L5.177 0.86896C5.3816 0.66692 5.71265 0.673742 5.90875 0.884039V0.884039C6.09829 1.0873 6.0903 1.40487 5.89079 1.59835L2.89873 4.5L5.89079 7.40165C6.0903 7.59513 6.09829 7.9127 5.90875 8.11596V8.11596Z" fill="#C6C6C6" />
              </svg>
            </button>
          </div>
          <button className="inline" onClick={createNewPrompt} style={{ height: 40, width: isLeftCollapseOpen ? '' : 40, padding: 10, background: 'transparent', border: '2px solid #E44867', display: 'flex', alignItems: 'center', justifyContent: 'center' }} disabled={!newOutputBtnIsActive}>
            <svg style={{ position: 'relative', left: isLeftCollapseOpen ? -5 : 0, top: isLeftCollapseOpen ? '' : 0 }} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14 7C14 7.54487 13.5583 7.98658 13.0134 7.98658H7.98658V13.0134C7.98658 13.5583 7.54487 14 7 14V14C6.45513 14 6.01342 13.5583 6.01342 13.0134V7.98658H0.986577C0.441705 7.98658 0 7.54487 0 7V7C0 6.45513 0.441706 6.01342 0.986578 6.01342H6.01342V0.986577C6.01342 0.441705 6.45513 0 7 0V0C7.54487 0 7.98658 0.441706 7.98658 0.986578V6.01342H13.0134C13.5583 6.01342 14 6.45513 14 7V7Z" fill="white" />
            </svg>

            {isLeftCollapseOpen ? 'New output' : ''}
          </button>

          {isLeftCollapseOpen ?
            <PromptTemplateList
              items={promptsList}
              isEditable={true}
              isDeletable={!isTheLastPrompt}
              onDelete={prompt => setPromptsList(promptsList.filter(x => x !== prompt))}
              currentItem={promptsList.find(x => x.prompt === currentPrompt.prompt)}
              onEdit={(prompt, newLabel) => {
                if (isBrieflyTemplatesSelected) {

                } else {
                  setPromptsList(promptsList.map(x => x.label === prompt.label ? { ...x, label: newLabel, } : x))
                }
              }}
              onClick={item => {


                const { prompt, label } = item

                if (!currentPrompt.prompt) {
                  updateCurrentPrompt(prompt)
                } if (promptsList.find(x => x.prompt == prompt)) {
                  setCurrentPrompt(promptsList.find(x => x.prompt == prompt))
                } else {
                  const newPrompt = { prompt: prompt, output: null, isLoading: false, error: false, label: prompt.slice(0, 25) + (prompt.length > 25 ? '...' : '') }
                  if (currentPrompt.output) {
                    newPrompt.output = currentPrompt.output
                    newPrompt.originalPrompt = currentPrompt.originalPrompt
                  }
                  setCurrentPrompt(newPrompt, promptsList.concat([newPrompt]))
                }
                setIsMyPromptNamingMode(false)
              }}
            /> : ''
          }
        </div>

        <div id="input-output-container" style={{ marginLeft: 20, marginRight: 20, maxWidth: 'unset', maxHeight: '100%', overflowY: 'auto', height: 'calc(100% - 20px)' }}>
          <div>

            <div className={"textarea-container " + (brieflyRecommendedPrompts?.prompts?.length > 0 && 'recommended-prompts-after')}>
              <textarea className="textarea" maxLength="1200" disabled={currentPrompt.isLoading} value={currentPrompt.prompt} onChange={e => updateCurrentPrompt(e.target.value)} ref={promptTextarea} placeholder='Enter your custom prompt to query your transcript ... Try something like "Turn this product brainstorm meeting into a Product Requirement Doc" or use one of Briefly custom made templates from the library.' />

              {/*{currentPrompt.prompt === '' ? <div className="textarea-placeholder" onClick={() => promptTextarea.current.focus()}>
                  <span>Enter your custom prompt to query your transcript ... Try something like "Turn this product brainstorm meeting into a Product Requirement Doc" or use one of Briefly custom made templates from the library.</span>
              </div> : null}*/}


            </div>


            <div style={{ display: 'flex', marginTop: 5 }}>

              <div className="checkbox-container" style={{ flex: 3, paddingTop: 15 }} >
                {!isMyPromptNamingMode ? <Checkbox style={!currentPrompt.prompt || brieflyRecommendedPrompts?.prompts?.find(x => x.prompt === currentPrompt.prompt) ? { fontSize: 14, pointerEvents: 'none', opacity: 0.7 } : { fontSize: 14 }} isChecked={myPrompts?.find(x => x.prompt === currentPrompt.prompt) || brieflyRecommendedPrompts?.prompts?.find(p => p.prompt == currentPrompt.prompt)} label="Save this prompt template" setIsChecked={isChecked => {
                  if (isChecked) {

                    setIsMyPromptNamingMode(true)
                    setPromptNameValue("")

                    // trackEvent("briefly-create-save-prompt", {
                    //   "prompt": currentPrompt.prompt
                    // })

                    // setMyPrompts(myPrompts.concat([currentPrompt.prompt]))
                  } else {
                    setMyPrompts(myPrompts.filter(p => p.prompt !== currentPrompt.prompt))
                  }
                }} /> : ''}

                {isMyPromptNamingMode ? <div className="my-prompt-naming-new">
                  <div className="svg" onClick={() => setIsMyPromptNamingMode(false)}>
                    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M11.396 0.604027C11.7296 0.937622 11.7296 1.47849 11.396 1.81208L7.20805 6L11.396 10.1879C11.7296 10.5215 11.7296 11.0624 11.396 11.396C11.0624 11.7296 10.5215 11.7296 10.1879 11.396L6 7.20805L1.81208 11.396C1.47849 11.7296 0.937622 11.7296 0.604027 11.396C0.270432 11.0624 0.270432 10.5215 0.604027 10.1879L4.79195 6L0.604027 1.81208C0.270432 1.47849 0.270432 0.937622 0.604027 0.604027C0.937622 0.270432 1.47849 0.270432 1.81208 0.604027L6 4.79195L10.1879 0.604027C10.5215 0.270432 11.0624 0.270432 11.396 0.604027Z" fill="#C6C6C6" />
                    </svg>
                  </div>

                  <input type="text" placeholder="Name Your Prompt" value={promptNameValue} onChange={e => setPromptNameValue(e.target.value)} />

                  <button disabled={!promptNameValue} onClick={() => {
                    trackEvent("briefly-create-save-prompt", {
                      "prompt": currentPrompt.prompt,
                      "label": promptNameValue
                    })

                    setMyPrompts(myPrompts.concat([{ prompt: currentPrompt.prompt, label: promptNameValue }]))

                    setIsMyPromptNamingMode(false)
                  }}>
                    Save
                  </button>

                </div> : ''}
              </div>

              <button disabled={!generateButtonIsActive} onClick={generateOutput} style={{ marginTop: 0, marginLeft: 16, flex: 2 }}>
                {currentPrompt.isLoading ? <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <ThreeDots
                    height="20"
                    width="20"
                    radius="9"
                    color="white"
                    ariaLabel="three-dots-loading"
                    wrapperStyle={{}}
                    wrapperClassName=""
                    visible={true}
                  />
                </div> : <>
                  Generate output {!subscriptionIsActive ? '[1 AI Credit]' : ''}
                </>}


              </button>

            </div>

          </div>

          {(() => {
            const { label, prompt, isLoading, output, error } = currentPrompt
            return <div id="ai-output" className={"gradient-border-container " + (currentPrompt.error && 'error ') + (output && ' loaded')}>
              <div className="gradient-border" />
              <div className="gradient-content">
                <h5>

                  <div>
                    <span className="copy-btn-container" style={output ? {} : { width: 0 }}>
                      {output ? <IconWithTimeoutReplaceOnClick onClick={() => {

                        trackEvent("briefly-create-copy-ai-output", {
                          "prompt": currentPrompt.prompt,
                          "output": output
                        })

                        copyToClipboard(output)

                      }} initialIcon={<svg style={{ cursor: 'pointer' }} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12.3234 14.5362V4.35745H4.35745V14.5362H12.3234ZM12.3234 2.92766C12.7092 2.92766 13.0496 3.07518 13.3447 3.37021C13.6397 3.64255 13.7872 3.97163 13.7872 4.35745V14.5362C13.7872 14.922 13.6397 15.2624 13.3447 15.5574C13.0496 15.8525 12.7092 16 12.3234 16H4.35745C3.97163 16 3.63121 15.8525 3.33617 15.5574C3.04113 15.2624 2.89362 14.922 2.89362 14.5362V4.35745C2.89362 3.97163 3.04113 3.64255 3.33617 3.37021C3.63121 3.07518 3.97163 2.92766 4.35745 2.92766H12.3234ZM10.1447 0V1.46383H1.42979V11.6426H0V1.46383C0 1.07801 0.13617 0.737589 0.408511 0.442553C0.703546 0.147518 1.04397 0 1.42979 0H10.1447Z" fill="#E44867" />
                      </svg>} clickedIcon={<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill="#E44867" d="M6.38498 12.0188L13.5962 4.80751L12.4695 3.64319L6.38498 9.7277L3.53052 6.87324L2.40376 8L6.38498 12.0188ZM2.32864 2.3662C3.9061 0.788732 5.79656 0 8 0C10.2034 0 12.0814 0.788732 13.6338 2.3662C15.2113 3.91862 16 5.79656 16 8C16 10.2034 15.2113 12.0939 13.6338 13.6714C12.0814 15.2238 10.2034 16 8 16C5.79656 16 3.9061 15.2238 2.32864 13.6714C0.776213 12.0939 0 10.2034 0 8C0 5.79656 0.776213 3.91862 2.32864 2.3662Z" />
                      </svg>
                      } /> : <span></span>}
                    </span>

                    <span>AI Output</span>
                  </div>

                </h5>
                <p style={{ minHeight: 210 }}>
                  {isLoading ? <div className="output-loader">
                    <LoadingSvg />
                    <div className="loader-container">
                      <div className="loader-inner" />
                    </div>
                    <h3>Please wait while we create your AI output...</h3>
                    <div className="typewriter-container">
                      <div className="typewriter line-1">
                        <div>Analyzing your transcript...</div>
                      </div>
                      <div className="typewriter line-2">
                        <div>Processing the information...</div>
                      </div>
                      <div className="typewriter line-3">
                        <div>Thinking...</div>
                      </div>
                      <div className="typewriter line-4">
                        <div>Generating results...</div>
                      </div>
                      <div className="typewriter line-5">
                        <div>It's taking longer than usual. We're still working on it...</div>
                      </div>
                    </div>
                  </div>
                    : output ? output.split('\n').map((x, i) => <div key={i}>{searchWords ? highlightText(x, searchWords, { background: '#F9C338', color: 'black' }) : x}</div>) : error ? <>
                      <div className="error">There was an issue with OpenAI while generating your output, try again later...</div>
                    </> : <span style={{ opacity: 0.5 }}>Create or choose a prompt to generate your personalised AI output...</span>}
                </p>
              </div>
            </div>
          })()}


        </div>
        <div id="ai-output-list" style={{ width: isRightCollapseOpen ? 268 : 45, minWidth: isRightCollapseOpen ? 268 : 45, padding: 16, borderLeft: '1px solid #212121' }} className={'_' + Math.min(promptsList.length, 7) + '-prompts'}>

          <div style={{ display: 'flex', justifyContent: 'space-between' }}>

            {isRightCollapseOpen ? <h3 style={{ margin: 0, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "18px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal", marginBottom: 12 }}>Prompt library</h3> : ''}

            <button id="hide-past-calls" style={{ width: 50, marginTop: 0 }} onClick={() => setIsRightCollapseOpen(!isRightCollapseOpen)}>
              {isRightCollapseOpen ? 'Hide' : ''}

              <svg style={{ transform: !isRightCollapseOpen ? '' : 'rotate(180deg)', left: isRightCollapseOpen ? 5 : 0 }} width="11" height="9" viewBox="0 0 11 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.90875 8.11596C8.71265 8.32626 8.3816 8.33308 8.177 8.13104L4.5 4.5L8.177 0.868959C8.3816 0.66692 8.71265 0.673742 8.90875 0.884039C9.09829 1.0873 9.0903 1.40487 8.89079 1.59835L5.89873 4.5L8.89079 7.40165C9.0903 7.59513 9.09829 7.9127 8.90875 8.11596Z" fill="#C6C6C6" />
                <path d="M5.90875 8.11596C5.71265 8.32626 5.3816 8.33308 5.177 8.13104L1.5 4.5L5.177 0.86896C5.3816 0.66692 5.71265 0.673742 5.90875 0.884039V0.884039C6.09829 1.0873 6.0903 1.40487 5.89079 1.59835L2.89873 4.5L5.89079 7.40165C6.0903 7.59513 6.09829 7.9127 5.90875 8.11596V8.11596Z" fill="#C6C6C6" />
              </svg>
            </button>

          </div>

          {isRightCollapseOpen ? <Dropdown isOpen={dropdownIsOpen} setIsOpen={setDropdownIsOpen} isBrieflyTemplatesSelected={isBrieflyTemplatesSelected} items={DROPDOWN_ITEMS} selectedItem={currentDropdownItem}
          /> : ''}

          {isRightCollapseOpen && brieflyRecommendedPromptsInBottom?.length > 0 && <PromptTemplateList
            items={(isBrieflyTemplatesSelected ? brieflyRecommendedPromptsInBottom : myPrompts)}
            isEditable={!isBrieflyTemplatesSelected}
            isDeletable={!isBrieflyTemplatesSelected}
            onDelete={prompt => setMyPrompts(myPrompts.filter(x => x !== prompt))}
            currentItem={(isBrieflyTemplatesSelected ? brieflyRecommendedPromptsInBottom : myPrompts).find(x => x.prompt === currentPrompt.prompt)}
            onEdit={(prompt, newLabel) => {
              if (isBrieflyTemplatesSelected) {

              } else {
                setMyPrompts(myPrompts.map(x => x.label === prompt.label ? { ...x, label: newLabel, } : x))
              }
            }}
            onClick={item => {


              const { prompt, label } = item

              trackEvent("briefly-create-use-saved-prompt", {
                "prompt": prompt,
                "label": label,
                "source": "BOTTOM_PANEL"
              })
              if (!currentPrompt.prompt) {
                updateCurrentPrompt(prompt)
              } if (promptsList.find(x => x.prompt == prompt)) {
                setCurrentPrompt(promptsList.find(x => x.prompt == prompt))
              } else {
                updateCurrentPrompt(prompt)
                // const newPrompt = { prompt:prompt, output: null, isLoading: false, error: false, label: prompt.slice(0, 25) + (prompt.length > 25 ? '...' : '') }
                // setCurrentPrompt(newPrompt, promptsList.concat([newPrompt]))
              }
              setIsMyPromptNamingMode(false)
            }}
          />}

        </div>
      </div>
    </div>
  );
}
