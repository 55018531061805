const AlertIcon = (props) => {
    return <svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g clipPath="url(#clip0_971_4675)">
            <path
                d="M6 6.59155C6.3267 6.59155 6.59155 6.3267 6.59155 6V3.57746C6.59155 3.25076 6.3267 2.98592 6 2.98592V2.98592C5.6733 2.98592 5.40845 3.25076 5.40845 3.57746V6C5.40845 6.3267 5.6733 6.59155 6 6.59155V6.59155ZM6 9.01408C6.3267 9.01408 6.59155 8.74924 6.59155 8.42254V8.39437C6.59155 8.06766 6.3267 7.80282 6 7.80282V7.80282C5.6733 7.80282 5.40845 8.06766 5.40845 8.39437V8.42254C5.40845 8.74924 5.6733 9.01408 6 9.01408V9.01408ZM1.74648 1.77465C2.92958 0.591549 4.34742 0 6 0C7.65258 0 9.06103 0.591549 10.2254 1.77465C11.4085 2.93897 12 4.34742 12 6C12 7.65258 11.4085 9.07042 10.2254 10.2535C9.06103 11.4178 7.65258 12 6 12C4.34742 12 2.92958 11.4178 1.74648 10.2535C0.58216 9.07042 0 7.65258 0 6C0 4.34742 0.58216 2.93897 1.74648 1.77465Z"
                fill="#FF3A3A"/>
        </g>
        <defs>
            <clipPath id="clip0_971_4675">
                <rect width="12" height="12" fill="white"/>
            </clipPath>
        </defs>
    </svg>
}

export default AlertIcon
