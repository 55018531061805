import ComputerMonitorSvg from '../../images/ComputerMonitorSvg'

export function BrieflyNotInstalledState(props) {
	return <div className="edge-case-state">
		<ComputerMonitorSvg/>
		<h3>To continue, please install Briefly extension.</h3>
		<a href="https://chrome.google.com/webstore/detail/briefly-ai-meeting-summar/bjmgcelbpkgmofiogkmleblcmecflldk" target="_blank" rel="noreferrer">
			<button className="inline">Get Briefly for Google Meet</button>
		</a>
	</div>
}

export default BrieflyNotInstalledState