import {isBrieflySuccess, logOut, sendUserFeedback} from "../utils/genericUtils";
import {createSearchParams, useNavigate} from "react-router-dom";
import useUserData from "./hooks/useUserData";
import React, {useMemo, useState} from "react";

const Onboarding = () => {
    const navigate = useNavigate()
    const [userData] = useUserData()

    const {
        subscription_is_active,
        admin_user,
        managed_users_not_confirmed,
        managed_users,
        admin_user_subscription_is_active,
        subscription_plan,
    } = userData
    const isProGivenForFree = subscription_plan === 'STANDARD_GIVEN_FOR_FREE' && !isTeamMemberAccount
    const isTeamMemberAccount = !!admin_user
    const isTeamAccount = managed_users ? (managed_users.concat(managed_users_not_confirmed)).length > 0 : false
    const [usingCRM, setUsingCRM] = useState(false)
    const [usingSlack, setUsingSlack] = useState(false)
    const [usingApps, setUsingApps] = useState([])
    const [usingCRMs, setUsingCRMs] = useState([])
    const [otherCRM, setOtherCRM] = useState('')
    const [otherApp, setOtherApp] = useState('')
    const search = useMemo(() => {
        return createSearchParams({
            usingApps: usingApps.join(','),
            usingCRMs: usingCRMs.join(','),
            usingSlack: usingSlack
        }).toString()
    }, [usingApps, usingSlack, usingCRMs])


    const handleRedirect = () => {
        if (otherCRM) {
            sendUserFeedback(`Onboarding meeting CRM OTHER: ${otherCRM}`, false, true)
        }
        if (otherApp) {
            sendUserFeedback(`Onboarding meeting APP OTHER: ${otherApp}`, false, true)
        }
        if (usingApps.length === 1 && usingApps[0] === 'Other' && (usingCRMs.length === 0 || usingCRMs.length === 1 && usingCRMs[0] === 'Other') && !usingSlack) {
            navigate(
                '/onboarding/notuser'
            )
        } else {
            navigate({
                pathname: '/onboarding/connecting/', search
            })
        }
    }

    const handleToggleApp = (app, isCRM) => {
        if (isCRM) {
            if (usingCRMs.includes(app.name)) {
                setUsingCRMs(usingCRMs.filter(a => a !== app.name))
            } else {
                setUsingCRMs([...usingCRMs, app.name])
            }
        } else {
            if (usingApps.includes(app.name)) {
                setUsingApps(usingApps.filter(a => a !== app.name))
            } else {
                setUsingApps([...usingApps, app.name])
            }
        }
    }


    const sendOnboardingData = async () => {

    }

    return (
        <div style={{
            background: '#141414',
            minHeight: '100vh',
            width: '100%',
            position: 'relative'

        }}>
            <div id="top-nav" style={{
                background: 'linear-gradient(90deg, #B700AF 0%, #F16617 100%)',
                display: 'flex',
                justifyContent: 'space-between'
            }}>
                <div>
                    <svg style={{
                        margin: '6px 15px',
                        marginTop: '10px'
                    }} width="90" height="25" viewBox="0 0 119 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M39.0792 29.7742V12.3396H45.3556C46.5716 12.3396 47.6803 12.4648 48.6816 12.7151C49.683 12.9655 50.4787 13.4036 51.0688 14.0294C51.6589 14.6553 51.954 15.5404 51.954 16.6849C51.954 17.4359 51.7573 18.1869 51.3639 18.938C50.9705 19.6711 50.434 20.1718 49.7545 20.44V20.5473C50.595 20.7798 51.3013 21.2268 51.8735 21.8884C52.4636 22.55 52.7586 23.462 52.7586 24.6243C52.7586 25.8224 52.4457 26.8059 51.8198 27.5748C51.194 28.3258 50.3625 28.8801 49.3254 29.2378C48.2882 29.5954 47.1438 29.7742 45.8921 29.7742H39.0792ZM43.6927 19.1525H45.2484C45.9994 19.1525 46.5537 18.9916 46.9113 18.6697C47.269 18.3479 47.4478 17.9187 47.4478 17.3822C47.4478 16.8458 47.269 16.4613 46.9113 16.2289C46.5537 15.9964 45.9994 15.8802 45.2484 15.8802H43.6927V19.1525ZM43.6927 26.2337H45.5702C47.3584 26.2337 48.2525 25.5899 48.2525 24.3024C48.2525 23.6587 48.0289 23.2027 47.5819 22.9345C47.1527 22.6663 46.4822 22.5322 45.5702 22.5322H43.6927V26.2337Z"
                            fill="white"/>
                        <path
                            d="M55.1272 29.7742V16.363H58.8823L59.2042 18.6697H59.3115C59.8122 17.7578 60.4023 17.0961 61.0818 16.6849C61.7791 16.2557 62.4586 16.0411 63.1203 16.0411C63.5315 16.0411 63.8624 16.068 64.1127 16.1216C64.3809 16.1574 64.6044 16.2199 64.7833 16.3094L64.0322 20.2791C63.7998 20.2254 63.5673 20.1807 63.3348 20.145C63.1024 20.0913 62.8342 20.0645 62.5302 20.0645C62.0474 20.0645 61.5377 20.2344 61.0013 20.5741C60.4827 20.896 60.0625 21.4771 59.7406 22.3176V29.7742H55.1272Z"
                            fill="white"/>
                        <path
                            d="M66.3381 29.7742V16.363H70.9516V29.7742H66.3381ZM68.6448 14.7C67.8938 14.7 67.2858 14.4944 66.8209 14.0831C66.356 13.6539 66.1235 13.0907 66.1235 12.3933C66.1235 11.6959 66.356 11.1416 66.8209 10.7303C67.2858 10.3011 67.8938 10.0865 68.6448 10.0865C69.3959 10.0865 70.0038 10.3011 70.4688 10.7303C70.9337 11.1416 71.1662 11.6959 71.1662 12.3933C71.1662 13.0907 70.9337 13.6539 70.4688 14.0831C70.0038 14.4944 69.3959 14.7 68.6448 14.7Z"
                            fill="white"/>
                        <path
                            d="M80.2881 30.0961C78.9827 30.0961 77.8025 29.8189 76.7475 29.2646C75.7104 28.7103 74.8878 27.9145 74.2799 26.8774C73.6719 25.8224 73.3679 24.5528 73.3679 23.0686C73.3679 21.6202 73.6719 20.3774 74.2799 19.3403C74.9057 18.2853 75.7104 17.4717 76.6939 16.8994C77.6774 16.3272 78.7145 16.0411 79.8053 16.0411C81.1285 16.0411 82.2193 16.3362 83.0776 16.9263C83.9538 17.4985 84.5976 18.2763 85.0088 19.2598C85.438 20.2433 85.6526 21.3341 85.6526 22.5322C85.6526 22.9256 85.6257 23.31 85.5721 23.6855C85.5363 24.0432 85.5006 24.3024 85.4648 24.4634H77.8204C78.035 25.268 78.4195 25.8313 78.9738 26.1532C79.5281 26.4572 80.1808 26.6092 80.9318 26.6092C81.7901 26.6092 82.7021 26.3409 83.6677 25.8045L85.1698 28.5404C84.4545 29.0411 83.6498 29.4255 82.7557 29.6937C81.8617 29.962 81.0391 30.0961 80.2881 30.0961ZM77.7668 21.4593H81.7365C81.7365 20.9407 81.6024 20.4937 81.3342 20.1181C81.0838 19.7247 80.61 19.528 79.9126 19.528C79.4119 19.528 78.9648 19.68 78.5714 19.984C78.1781 20.288 77.9098 20.7798 77.7668 21.4593Z"
                            fill="white"/>
                        <path
                            d="M88.8312 29.7742V19.9572H87.061V16.5239L88.8312 16.3898V16.2021C88.8312 15.2365 88.9922 14.3424 89.314 13.5198C89.6538 12.6973 90.2081 12.0356 90.977 11.535C91.7638 11.0343 92.8188 10.7839 94.1421 10.7839C94.7679 10.7839 95.3223 10.8376 95.8051 10.9449C96.3058 11.0522 96.6992 11.1594 96.9853 11.2667L96.1806 14.6464C95.6978 14.4675 95.2507 14.3781 94.8395 14.3781C94.4103 14.3781 94.0706 14.5033 93.8202 14.7537C93.5699 14.9861 93.4447 15.4153 93.4447 16.0411V16.363H95.6978V19.9572H93.4447V29.7742H88.8312Z"
                            fill="white"/>
                        <path
                            d="M101.823 30.0961C100.786 30.0961 99.963 29.8904 99.3551 29.4792C98.765 29.05 98.3358 28.4599 98.0676 27.7089C97.8172 26.94 97.6921 26.0548 97.6921 25.0535V11.1058H102.306V25.2144C102.306 25.6614 102.386 25.9744 102.547 26.1532C102.726 26.3141 102.896 26.3946 103.057 26.3946C103.146 26.3946 103.217 26.3946 103.271 26.3946C103.343 26.3767 103.432 26.3588 103.539 26.3409L104.076 29.7206C103.843 29.8279 103.539 29.9173 103.164 29.9888C102.788 30.0603 102.341 30.0961 101.823 30.0961Z"
                            fill="white"/>
                        <path
                            d="M107.666 34.8168C107.219 34.8168 106.843 34.79 106.539 34.7364C106.236 34.6827 105.932 34.6201 105.628 34.5486L106.432 31.0617C106.557 31.0796 106.7 31.1064 106.861 31.1422C107.022 31.1958 107.165 31.2226 107.291 31.2226C107.916 31.2226 108.39 31.0975 108.712 30.8471C109.034 30.5968 109.257 30.2749 109.383 29.8815L109.544 29.2914L104.447 16.363H109.061L110.67 21.5129C110.867 22.1209 111.037 22.7378 111.18 23.3637C111.323 23.9895 111.475 24.6422 111.636 25.3217H111.743C111.886 24.678 112.02 24.0432 112.145 23.4173C112.288 22.7914 112.44 22.1566 112.601 21.5129L113.942 16.363H118.341L113.835 29.5596C113.37 30.7577 112.87 31.7412 112.333 32.5101C111.815 33.2969 111.18 33.878 110.429 34.2536C109.696 34.6291 108.775 34.8168 107.666 34.8168Z"
                            fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M32.4903 8.01119C32.7999 7.82501 33.2019 7.9251 33.3881 8.23474L33.7106 8.77115C36.317 13.1058 33.7562 18.7059 28.7726 19.5699L28.3833 19.6374L28.7112 19.9286C33.1047 23.8298 30.5274 31.0955 24.6577 31.3561L22.6513 31.4452L0.817433 32.2533C0.456373 32.2667 0.152842 31.9848 0.139479 31.6238C0.126115 31.2627 0.40798 30.9592 0.76904 30.9458L22.5956 30.138L22.6005 30.1378L24.5997 30.049C29.2955 29.8405 31.3573 24.0279 27.8425 20.907L25.4833 18.8122L28.5491 18.2807C32.6266 17.5738 34.7218 12.9919 32.5893 9.44538L32.2667 8.90897C32.0806 8.59932 32.1806 8.19738 32.4903 8.01119Z"
                              fill="white"/>
                        <path fillRule="evenodd" clipRule="evenodd"
                              d="M6.36892 23.3956C6.4285 18.3638 8.24859 13.4641 11.0437 9.68252L11.8387 18.1786L11.7845 18.1836L11.8394 18.1869L11.8446 18.2417L11.8477 18.1873L20.6763 18.7035C16.8889 21.7674 12.1117 23.6642 6.88394 23.8358C5.70664 26.3012 3.79692 28.3574 1.40102 29.7131L1.23397 29.8077C2.22815 27.0673 3.93696 24.9761 6.36892 23.3956ZM11.8483 18.1777L12.4454 7.96369C13.3295 6.97935 14.2968 6.07084 15.3366 5.24939L16.3155 13.5396L16.2629 13.5458L16.3167 13.5503L16.3231 13.6039L16.3274 13.5512L24.8896 14.2602C24.1312 15.3069 23.2871 16.2881 22.3676 17.1933L11.8483 18.1777ZM16.3285 13.5381L17.1207 3.97203C17.9323 3.44711 18.779 2.97148 19.6567 2.54944L20.7551 8.93617L20.7064 8.94454L20.7577 8.95154L20.7665 9.00263L20.7732 8.95364L27.3968 9.856C27.0207 10.7269 26.5926 11.5703 26.1164 12.3824L16.3285 13.5381ZM20.776 8.93256L21.7666 1.6612C24.1152 0.807394 26.643 0.321986 29.2816 0.27593C29.3265 2.84733 28.9507 5.32819 28.2176 7.65283L20.776 8.93256Z"
                              fill="white"/>
                    </svg>
                </div>

                <div style={{display: 'flex', alignItems: 'center'}}>

                    {!isProGivenForFree && !userData.error && !subscription_is_active && !isTeamMemberAccount || (isTeamMemberAccount && !admin_user_subscription_is_active) ?
                        <div id="free-ai-credits-left">
                            {userData.briefly_ai_credits_left} free AI credits left
                            <div className="ai-credits-help-container">
                                <svg style={{cursor: 'pointer', position: 'relative', top: 2, marginLeft: 3}}
                                     openOnClick={true} data-tooltip-id={"tooltip-button-ai-credits"} width="12"
                                     height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M7.83099 6.0493C8.20657 5.67371 8.39437 5.223 8.39437 4.69718C8.39437 4.03991 8.15962 3.47653 7.69014 3.00704C7.22066 2.53756 6.65728 2.30282 6 2.30282C5.34272 2.30282 4.77934 2.53756 4.30986 3.00704C3.98644 3.33046 3.77442 3.69844 3.6738 4.11098C3.59638 4.42838 3.87048 4.69718 4.19718 4.69718C4.52389 4.69718 4.77519 4.41515 4.93788 4.13184C4.99426 4.03368 5.0666 3.94044 5.15493 3.85211C5.39906 3.60798 5.68075 3.48592 6 3.48592C6.31925 3.48592 6.60094 3.60798 6.84507 3.85211C7.0892 4.09624 7.21127 4.37793 7.21127 4.69718C7.21127 5.01643 7.0892 5.29812 6.84507 5.54225L6.11268 6.30282C5.64319 6.80986 5.40845 7.37324 5.40845 7.99296C5.40845 8.16409 5.54718 8.30282 5.71831 8.30282H6C6.3267 8.30282 6.5831 8.03365 6.66565 7.71755C6.76853 7.32354 6.97858 6.95525 7.29578 6.61268L7.83099 6.0493ZM6 10.6972C6.3267 10.6972 6.59155 10.4323 6.59155 10.1056C6.59155 9.77893 6.3267 9.51408 6 9.51408C5.6733 9.51408 5.40845 9.77893 5.40845 10.1056C5.40845 10.4323 5.6733 10.6972 6 10.6972ZM1.74648 2.27465C2.92958 1.09155 4.34742 0.5 6 0.5C7.65258 0.5 9.06103 1.09155 10.2254 2.27465C11.4085 3.43897 12 4.84742 12 6.5C12 8.15258 11.4085 9.57042 10.2254 10.7535C9.06103 11.9178 7.65258 12.5 6 12.5C4.34742 12.5 2.92958 11.9178 1.74648 10.7535C0.58216 9.57042 0 8.15258 0 6.5C0 4.84742 0.58216 3.43897 1.74648 2.27465Z"
                                        fill="white"/>
                                </svg>
                                <div className="ai-credits-popup" style={{
                                    width: 200,
                                    fontSize: 12,
                                    fontStyle: 'normal',
                                    fontWeight: 400,
                                    letterSpacing: 0.3,
                                    lineHeight: '18px',
                                    marginLeft: '-5px',
                                    marginRight: '-5px',
                                    zIndex: 99999999999
                                }}>
                                    <p style={{margin: 0, marginBottom: 10}}>
                                        AI summary, BrieflyCreate Output or email follow up generation, will use 1 AI
                                        credit. Transcriptions and transcript uploads are unlimited on your free plan.
                                    </p>
                                    <a onClick={() => navigate('/settings/subscriptions')}
                                       style={{fontWeight: 700, textDecoration: 'underline', cursor: 'pointer'}}>Upgrade
                                        now</a>
                                </div>
                            </div>

                        </div> : ''}
                    {!userData.error && !isProGivenForFree ?
                        <div className="subscription-status badge" onClick={() => navigate(`/settings/subscriptions`)}
                             style={{
                                 background: 'white',
                                 width: 'unset',
                                 color: '#F97238',
                                 padding: '0 5px',
                                 display: 'flex',
                                 alignItems: 'center',
                                 marginRight: 10,
                                 cursor: 'pointer'
                             }}>
                            <svg style={{position: 'relative', marginRight: 3, marginLeft: 3}} width="12" height="12"
                                 viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M5.04894 1.42705C5.3483 0.505738 6.6517 0.50574 6.95106 1.42705L7.5716 3.33688C7.70547 3.7489 8.08943 4.02786 8.52265 4.02786H10.5308C11.4995 4.02786 11.9023 5.26748 11.1186 5.83688L9.49395 7.01722C9.14347 7.27187 8.99681 7.72323 9.13068 8.13525L9.75122 10.0451C10.0506 10.9664 8.9961 11.7325 8.21238 11.1631L6.58778 9.98278C6.2373 9.72813 5.7627 9.72814 5.41221 9.98278L3.78761 11.1631C3.0039 11.7325 1.94942 10.9664 2.24878 10.0451L2.86932 8.13526C3.00319 7.72323 2.85653 7.27186 2.50604 7.01722L0.881445 5.83688C0.0977311 5.26748 0.500508 4.02786 1.46923 4.02786H3.47735C3.91057 4.02786 4.29453 3.7489 4.4284 3.33688L5.04894 1.42705Z"
                                    fill="#F97238"/>
                            </svg>
                            {!(subscription_is_active || (isTeamMemberAccount && admin_user_subscription_is_active)) ? 'UPGRADE TO PRO' : isTeamAccount || isTeamMemberAccount ? 'TEAM' : 'PRO'}
                        </div> : ''}
                    <div className="user-dropdown">
                        {userData?.email && <>
                            <div className="label">
                                {userData.email}
                                <svg width="12" height="7" viewBox="0 0 12 7" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M0.576058 0.261882C0.260613 0.556031 0.25038 1.0526 0.553439 1.3595L6 6.875L11.4466 1.3595C11.7496 1.0526 11.7394 0.556032 11.4239 0.261883C11.119 -0.022429 10.6427 -0.010456 10.3525 0.288814L6 4.7769L1.64753 0.288814C1.35731 -0.0104558 0.880954 -0.0224294 0.576058 0.261882Z"
                                        fill="white"/>
                                </svg>
                            </div>

                            <div className="user-dropdown-content" style={{height: isBrieflySuccess() ? 60 : 215}}>

                                {isBrieflySuccess() ? <>

                                </> : <>
                                    <h6>Settings</h6>

                                    <div onClick={() => navigate('/settings/ai-summary-email')} className="clickable">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M14.4225 5.01754V3.50877L8 7.26316L1.57746 3.50877V5.01754L8 8.73684L14.4225 5.01754ZM14.4225 2C14.8482 2 15.2113 2.15205 15.5117 2.45614C15.8372 2.76023 16 3.11111 16 3.50877V12.4912C16 12.8889 15.8372 13.2398 15.5117 13.5439C15.2113 13.848 14.8482 14 14.4225 14H1.57746C1.1518 14 0.776213 13.848 0.450704 13.5439C0.150235 13.2398 0 12.8889 0 12.4912V3.50877C0 3.11111 0.150235 2.76023 0.450704 2.45614C0.776213 2.15205 1.1518 2 1.57746 2H14.4225Z"
                                                fill="white"/>
                                        </svg>
                                        AI Summary Email
                                    </div>

                                    <div onClick={() => navigate('/settings/subscriptions')} className="clickable">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M8.53755 13.1822C8.20966 12.9732 7.79034 12.9732 7.46245 13.1822L5.07922 14.7015C4.32781 15.1805 3.37242 14.5116 3.56541 13.6417L4.23172 10.638C4.30912 10.2891 4.19441 9.92535 3.93084 9.68396L1.67237 7.61557C1.03133 7.02848 1.39294 5.95946 2.25874 5.88208L5.1377 5.62478C5.51141 5.59138 5.83499 5.35159 5.9757 5.00378L7.07299 2.29144C7.4101 1.45814 8.5899 1.45814 8.92701 2.29144L10.0243 5.00378C10.165 5.35159 10.4886 5.59138 10.8623 5.62478L13.7413 5.88208C14.6071 5.95946 14.9687 7.02848 14.3276 7.61557L12.0692 9.68396C11.8056 9.92535 11.6909 10.2891 11.7683 10.638L12.4346 13.6417C12.6276 14.5116 11.6722 15.1805 10.9208 14.7015L8.53755 13.1822Z"
                                                fill="white"/>
                                        </svg>
                                        Subscription
                                    </div>


                                    <div onClick={() => navigate('/settings/integrations')} className="clickable">
                                        <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <g clipPath="url(#clip0_1276_2192)">
                                                <path fillRule="evenodd" clipRule="evenodd"
                                                      d="M3.70526 11.6645L1.81592 12.7553C1.32512 13.0387 0.697539 12.8705 0.414177 12.3797C0.130815 11.8889 0.298975 11.2613 0.789772 10.978L2.67912 9.88716C2.18856 8.12618 2.92626 6.18982 4.58259 5.23353L8.52605 2.95677C8.80213 2.79738 9.15514 2.89197 9.31453 3.16805L9.53897 3.55679L12.2049 2.01762C12.6957 1.73426 13.3233 1.90242 13.6066 2.39322C13.89 2.88401 13.7218 3.51159 13.231 3.79496L10.5651 5.33412L11.5912 7.11139L14.257 5.5723C14.7478 5.28893 15.3754 5.45709 15.6587 5.94789C15.9421 6.43869 15.774 7.06627 15.2832 7.34963L12.6174 8.88873L12.8419 9.27764C13.0013 9.55371 12.9067 9.90673 12.6306 10.0661L8.68717 12.3429C7.03083 13.2992 4.98503 12.9698 3.70526 11.6645Z"
                                                      fill="white"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_1276_2192">
                                                    <rect width="16" height="16" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                        Integrations
                                    </div>

                                    <hr style={{marginBottom: 14, marginTop: -7}}/>
                                </>}


                                <div className="clickable"
                                     onClick={() => window.open('https://www.brieflyai.com/terms-of-service', '_blank')}
                                     className="clickable">
                                    Terms and Conditions
                                </div>

                                <div onClick={() => logOut(true)} className="clickable">
                                    Log Out
                                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M1.38028 2.24674V11.7533H6.31413C6.69292 11.7533 7 12.0603 7 12.4391V12.4391C7 12.8179 6.69292 13.125 6.31413 13.125H1.38028C1.00782 13.125 0.679186 12.9868 0.394366 12.7103C0.131455 12.4338 0 12.1148 0 11.7533V2.24674C0 1.8852 0.131455 1.56619 0.394366 1.28971C0.679186 1.01324 1.00782 0.875 1.38028 0.875H6.31413C6.69292 0.875 7 1.18208 7 1.56087V1.56087C7 1.93967 6.69292 2.24674 6.31413 2.24674H1.38028ZM10.0348 4.05416C10.3027 3.79405 10.7292 3.79505 10.9959 4.05641L13.7503 6.75535C13.8874 6.88965 13.8874 7.11034 13.7503 7.24465L11.0097 9.93012C10.7366 10.1977 10.2988 10.1951 10.0289 9.92438V9.92438C9.75473 9.64938 9.75839 9.20329 10.037 8.93281L11.338 7.66992H4.87649C4.50651 7.66992 4.20657 7.36999 4.20657 7V7C4.20657 6.63001 4.50651 6.33008 4.87649 6.33008H11.338L10.0303 5.0376C9.75598 4.76646 9.75798 4.32282 10.0348 4.05416V4.05416Z"
                                            fill="white"/>
                                    </svg>
                                </div>

                            </div>
                        </>}
                    </div>
                </div>
            </div>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: 'column',
                    maxWidth: 600,
                    margin: 'auto',
                    position: 'absolute',
                    left: 0,
                    right: 0,
                    top: '25%',
                }}>
                <p style={{
                    textAlign: 'center',
                    color: 'white',
                    margin: 0,
                    fontSize: 32,
                    marginBottom: 24,
                    fontFamily: 'Source Sans Pro',
                    fontWeight: '900',
                    wordWrap: 'break-word'
                }}>Welcome to Briefly!
                </p>

                <div style={{
                    padding: 24,
                    background: '#212121',
                    borderRadius: 4,
                    border: '1px #6D6D6D solid',
                    gap: 24,
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <div>
                        <p style={{
                            width: '100%',
                            color: 'white',
                            fontSize: 20,
                            fontFamily: 'Source Sans Pro',
                            margin: 0,
                            marginBottom: 10,
                            fontWeight: '900',
                            wordWrap: 'break-word'
                        }}>Customize your setup
                        </p>
                        <div style={{
                            width: '100%',
                            color: '#C6C6C6',
                            fontSize: 12,
                            fontFamily: 'Roboto',
                            fontWeight: '400',
                            margin: 0,
                            wordWrap: 'break-word'
                        }}>Let’s tailor your Briefly setup to match how you work. You’ll also be able to manage and add
                            more
                            integrations later in the settings.
                        </div>
                    </div>
                    <p style={{
                        width: '100%',
                        color: 'white',
                        fontSize: 16,
                        margin: 0,
                        fontFamily: 'Roboto',
                        fontWeight: '500',
                        wordWrap: 'break-word'
                    }}>Which meeting app do you use?*
                    </p>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        gap: 16,
                    }}>
                        {apps.map(app =>
                            <AppCard onClick={() => handleToggleApp(app)}
                                     selected={!!usingApps.filter(a => a === app.name).length}
                                     app={app}/>)}

                    </div>
                    <div
                        style={{
                            width: '100%', display: 'flex',
                            overflow: 'hidden',
                            height: usingApps.find(e => e === 'Other') ? 40 : 0,
                            transition: 'height 0.5s',
                            transitionTimingFunction: 'ease-in-out'
                        }}
                    >
                        <input
                            value={otherApp}
                            onChange={e => setOtherApp(e.target.value)}
                            className={"other-input"}
                            placeholder="Which other app are you using?"/>
                    </div>
                    <hr style={{margin: '1px 0', background: '#434343'}}/>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'space-between',
                        }}>
                            <p style={{
                                color: 'white',
                                fontSize: 16,
                                margin: 0,
                                fontFamily: 'Roboto',
                                fontWeight: '500',
                                letterSpacing: 0.15,
                                wordWrap: 'break-word'
                            }}>Do you use a CRM system?
                            </p>
                            <label className="switch">
                                <input type="checkbox" checked={usingCRM} onClick={e => {
                                    const checked = e.target.checked
                                    setUsingCRM(checked)
                                }}/>
                                <span className="slider round"></span>
                            </label>
                        </div>
                        <div style={{
                            display: 'flex',
                            flexDirection: 'row',
                            width: '100%',
                            gap: 16,
                            marginTop: 24,
                            height: usingCRM ? 40 : 0,
                            overflow: 'hidden',
                            transition: 'height 0.5s',
                            transitionTimingFunction: 'ease-in-out'

                        }}>
                            {CRMs.map(app =>
                                <AppCard onClick={() => handleToggleApp(app, true)}
                                         selected={!!usingCRMs.filter(a => a === app.name).length}
                                         app={app}/>)}
                        </div>
                        <div
                            style={{
                                width: '100%', display: 'flex',
                                overflow: 'hidden',
                                marginTop: 24,
                                height: usingCRMs.find(e => e === 'Other') ? 40 : 0,
                                transition: 'height 0.5s',
                                transitionTimingFunction: 'ease-in-out'
                            }}
                        >
                            <input
                                value={otherCRM}
                                onChange={e => setOtherCRM(e.target.value)}
                                className={"other-input"}
                                placeholder="Which other CRM system are you using?"/>
                        </div>
                    </div>
                    <hr style={{margin: '1px 0', background: '#434343'}}/>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}>
                        <p style={{
                            color: 'white',
                            fontSize: 16,
                            margin: 0,
                            fontFamily: 'Roboto',
                            fontWeight: '500',
                            letterSpacing: 0.15,
                            wordWrap: 'break-word'
                        }}>Do you use Slack?
                        </p>
                        <label className="switch">
                            <input type="checkbox" checked={usingSlack} onClick={e => {
                                const checked = e.target.checked
                                setUsingSlack(checked)
                            }}/>
                            <span className="slider round"></span>
                        </label>
                    </div>
                </div>
                <button disabled={usingApps.length === 0} onClick={handleRedirect}>
                    Confirm and customize Integrations
                </button>

            </div>
        </div>
    );
}


export default Onboarding;


const apps = [
    {
        name: 'Google Meet',
    },
    {
        name: 'Zoom',
    },
    {
        name: 'Other',
    },

]
const CRMs = [
    {
        name: 'Salesforce',
    },
    {
        name: 'Hubspot',
    },
    {
        name: 'Other',
    },
]

const AppCard = ({app, onClick, selected}) => {
    return (
        <div onClick={() => onClick(e => !e)} style={{
            background: selected === app ? '#E44867' : '#212121',
            borderRadius: 70,
            width: 'fit-content',
            height: 40,
            padding: '8px 16px',
            boxSizing: 'border-box',
            display: 'flex',
            gap: 8,
            margin: 0,
            userSelect: 'none',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
            border: '2px #E44867 solid',
            color: 'white'
        }}>
            {selected ? <>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="14" height="14" rx="1" fill="white" stroke="#434343" strokeWidth="2"/>
                    <path d="M5 8L6.84515 9.97694C7.31321 10.4784 8.13573 10.3705 8.45855 9.76521L11 5"
                          stroke="#E44867" strokeWidth="3" strokeLinecap="round"/>
                </svg>
            </> : <>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="1" y="1" width="14" height="14" rx="1" fill="#C6C6C6" stroke="#434343"
                          strokeWidth="2"/>
                </svg>
            </>}
            <div style={{
                color: 'white',
                fontSize: 16,
                fontFamily: 'Source Sans Pro',
                fontWeight: '600',
                wordWrap: 'break-word'
            }}>
                {app.name}
            </div>
        </div>
    );
}
