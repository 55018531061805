import { useState, useEffect } from 'react';

export function useBrieflyExtensionVersion() {
  const [version, setVersion] = useState(null);

  async function getVersion() {
    const promise = new Promise(resolve => {
      const handler = e => {
        if(e.detail.getExtensionVersion) {
          window.removeEventListener("background-message-response", handler)
          resolve(e.detail.getExtensionVersion)
        }
      }

      window.addEventListener("background-message-response", handler)
    })

    window.dispatchEvent(new CustomEvent('background-message', { detail: { getExtensionVersion: true }}))

    const versionData = await promise

    setVersion( versionData?.version )
  }

  useEffect(() => {
    getVersion()

    return () => {

    };
  }, []);

  return version;
}