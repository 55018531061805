import { useState, useMemo, useEffect } from 'react'
import { groupConsecutiveByDeviceId, getTranscriptionAsText, copyToClipboard, highlightText,
  checkIfStandardSummaryIsEligible,
  checkIfCustomSummaryIsEligible
} from '../../utils/genericUtils'
import { ButtonWithTimeoutReplaceOnClick } from '../../utils/ButtonWithTimeoutReplaceOnClick'


export default function MeetingTabTranscript({ meetingData, searchWords }) {

  const { deviceColorsById, deviceNamesById, transcriptionBlocks } = meetingData

  const groupedTranscriptionBlocks = useMemo(() => transcriptionBlocks ? groupConsecutiveByDeviceId(transcriptionBlocks) : [], [transcriptionBlocks])

  const [scrollExecutedForMeetingIdAndSearchWords, setScrollExecutedForMeetingIdAndSearchWords] = useState([null, null])

  useEffect(() => {
    if(searchWords) {
      if(JSON.stringify(scrollExecutedForMeetingIdAndSearchWords) === JSON.stringify([meetingData.meetingId, searchWords])) {
        return
      }
      setScrollExecutedForMeetingIdAndSearchWords([meetingData.meetingId, searchWords])
      setTimeout(() => {
        const scrollTo = document.querySelector("#transcript-container .highlight")
        if(scrollTo) {
          scrollTo.scrollIntoView({ behavior: 'smooth', block: 'center'})
        }
      }, 100)
    }
  }, [meetingData.meetingId, searchWords])

  return (
    <div id="transcript-outer-container">
      <div id="transcript-container">

        <div style={{ marginBottom: 10 }}>
          <ButtonWithTimeoutReplaceOnClick

            initialText={
              <>
                <svg style={{ position: 'relative', left: -7, top: 0 }} width="13" height="15" viewBox="0 0 13 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M11.2512 13.2191V4.31277H4.28103V13.2191H11.2512ZM11.2512 3.0617C11.5888 3.0617 11.8867 3.19078 12.1449 3.44894C12.403 3.68723 12.5321 3.97518 12.5321 4.31277V13.2191C12.5321 13.5567 12.403 13.8546 12.1449 14.1128C11.8867 14.3709 11.5888 14.5 11.2512 14.5H4.28103C3.94344 14.5 3.64557 14.3709 3.38741 14.1128C3.12925 13.8546 3.00018 13.5567 3.00018 13.2191V4.31277C3.00018 3.97518 3.12925 3.68723 3.38741 3.44894C3.64557 3.19078 3.94344 3.0617 4.28103 3.0617H11.2512ZM9.34486 0.5V1.78085H1.71933V10.6872H0.468262V1.78085C0.468262 1.44326 0.587411 1.14539 0.825709 0.887234C1.08386 0.629078 1.38174 0.5 1.71933 0.5H9.34486Z" fill="white"/>
                </svg>
                Copy transcript
              </>
            }

            clickedText={
              <>
                <svg style={{ position: 'relative', left: -7, top: 0 }} width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill="white" d="M6.38498 12.0188L13.5962 4.80751L12.4695 3.64319L6.38498 9.7277L3.53052 6.87324L2.40376 8L6.38498 12.0188ZM2.32864 2.3662C3.9061 0.788732 5.79656 0 8 0C10.2034 0 12.0814 0.788732 13.6338 2.3662C15.2113 3.91862 16 5.79656 16 8C16 10.2034 15.2113 12.0939 13.6338 13.6714C12.0814 15.2238 10.2034 16 8 16C5.79656 16 3.9061 15.2238 2.32864 13.6714C0.776213 12.0939 0 10.2034 0 8C0 5.79656 0.776213 3.91862 2.32864 2.3662Z"/>
                </svg>
                Copied to clipboard
              </>
            }

            onClick={() => {
              copyToClipboard(getTranscriptionAsText(meetingData))
            }}

          />
        </div>

        {groupedTranscriptionBlocks.map(({ deviceId, blocks }) => {
          const deviceColor = deviceColorsById[deviceId]
          const deviceName = deviceNamesById[deviceId]

          return <div key={blocks[0].messageId} className="transcription-block">
            <h6 className="device-name" style={{ color: deviceColor}}>{deviceName}</h6>
            <br/>
            <p>
              {blocks.map(x => x.text).join(' ').split('\n').map((x, i) => <>{i > 0 && <br/>}{searchWords ? highlightText(x, searchWords, {background: '#F9C338', color: 'black'}) : x}</>)}
            </p>
          </div>
        })}
      </div>
    </div>

  );
}
