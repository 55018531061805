import theme, { colors } from "../../../theme";

const type: Record<string, React.CSSProperties> = {
  helpText: {
    fontSize: 14,
    letterSpacing: 0.25,
  },
};

const style: Record<string, React.CSSProperties> = {
  container: {
    display: "flex",
    flexDirection: "column",
    padding: "0 30px",
    gap: 30,
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderBottom: `1px solid ${colors.gray500}`,
    padding: "24px 0",
  },
  headerRight: {
    ...type.helpText,
    display: "flex",
    alignItems: "center",
    gap: 8,
  },
  headerRightLink: {
    ...theme.buttons.link,
    ...theme.link,
  },
  itemList: {
    display: "flex",
    flexDirection: "column",
    gap: 20,
  },
  itemHeading: {
    display: "flex",
    alignItems: "center",
    gap: 10,
    fontSize: 16,
    letterSpacing: 0.2,
  },
  itemStatus: {
    ...type.helpText,
    display: "flex",
    alignItems: "center",
    letterSpacing: 0.25,
    gap: 6,
  },
  itemSummary: {
    display: "flex",
    cursor: "pointer",
    justifyContent: "space-between",
  },
  item: {
    padding: "6px 0",
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  fieldIcon: {
    width: 12,
    height: 12,
  },
  checkMarkIcon: {
    color: colors.green500,
  },
  collapseIcon: {
    width: 28,
  },
  inputLabel: {
    ...type.helpText,
  },
  itemDetail: {
    display: "flex",
    // grid-template-columns: 50% 50%;
    // grid-template-rows: 1fr;
    // overflow: hidden;
    // transition: max-height 0.5s;
    // transition-timing-function: ease-in-out;
    // max-height: 0;
  },
  inputContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 14,
  },
  verticalDivider: {
    display: "inline-block",
    width: 1,
    backgroundColor: colors.gray300,
    marginLeft: 12,
    marginRight: 24,
  },
  input: {
    ...type.helpText,
    resize: "none",
    padding: "8px 16px",
    backgroundColor: colors.gray800,
    borderColor: colors.gray500,
    borderRadius: 6,
    flexGrow: 1,
    color: colors.white,
    maxHeight: 'calc(100vh - 500px)',
    overflow: 'auto',
  },
  inputPreview: {
    border: 0,
  },
  previousValueContainer: {
    flexGrow: 1.8,
  },
  nextValueContainer: {
    flexGrow: 1,
  },
  actions: {
  },
  buttonOutline: {
    ...theme.buttons.secondary,
    ...type.helpText,
    width: "auto",
    height: "auto",
    padding: "6px 30px",
  },
  buttonSolid: {
    ...theme.buttons.primary,
    flexGrow: 1,
    width: "auto",
    height: "auto",
    padding: "6px 30px",
    ...type.helpText,
  },
  disabledText: {
    color: colors.gray300,
  },
  dialogOverlay: {
    backgroundColor: 'rgba(0 0 0 / 0.5)',
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    placeItems: 'center',
    overflowY: 'auto',
    zIndex: 999,
  },
  dialogBox: {
    boxSizing: 'border-box',
    width: 600,
    background: colors.gray900,
    color: colors.white,
    padding: 22,
    borderRadius: 6,
  },
};

export default style;
