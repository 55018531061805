import { useState } from 'react'

export function IconWithTimeoutReplaceOnClick({ initialIcon, clickedIcon, onClick }) {

	const [isClicked, setIsClicked] = useState(false)

	if(isClicked) {
		return clickedIcon
	} else {
		return <span onClick={() => {
			setIsClicked(true)
			setTimeout(() => {
				setIsClicked(false)
			}, 1000)

			onClick()
		}}>
			{initialIcon}
		</span>
	}
}

export default IconWithTimeoutReplaceOnClick