import { useState, useEffect } from 'react';

export function useBrieflyExtensionStorageArray({ storageKeys, defaultValue, processOnInitialLoad, delay }) {
  const [data, setData] = useState(undefined);

  async function syncDataFromStorage() {

    const promise = new Promise(resolve => {
      const handler = e => {
        if (e.detail.getStorageDataByKeysArray) {
          window.removeEventListener("background-message-response", handler)
          resolve(e.detail.getStorageDataByKeysArray.data)
        }
      }

      window.addEventListener("background-message-response", handler)
    })

    window.dispatchEvent(new CustomEvent('background-message', {
      detail: {
        getStorageDataByKeysArray: {
          keys: storageKeys,
          defaultValue: defaultValue
        }
      }
    }))

    const storageData = await promise
    setData(processOnInitialLoad ? processOnInitialLoad(storageData) : storageData)
  }

  useEffect(() => {
    setTimeout(syncDataFromStorage, delay ? 100 : 0)

    return () => {

    };
  }, []);

  // useEffect(() => {
  //   setTimeout(syncDataFromStorage, delay ? 100 : 0)
  // }, [storageKeys]);

  return [data, data === undefined];
}
