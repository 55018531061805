import React from 'react';
import AddIcon from "../../Icons/Add";
import MinusIcon from "../../Icons/Minus";
import AlertIcon from "../../Icons/Alert";
import CheckmarkIcon from "../../Icons/Checkmark";
import ExternalLink from "../../Icons/ExternalLink";

import style from "./style";
import EditableContentTextarea from '../../forms/EditableContentTextarea';
import styled from 'styled-components';
import { Button, colors } from '../../../theme';

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const Grid = styled.div`
  flex-grow: 1;

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 1fr 0fr;
  grid-column-gap: 24px;
  grid-row-gap: 12px;

  & > *:nth-child(1) {
    grid-area: 1 / 1 / 3 / 2;
  }
  & > *:nth-child(2) { grid-area: 1 / 2 / 2 / 3; }
  & > *:nth-child(3) {
    grid-area: 2 / 2 / 3 / 3;
  }
`

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const Actions = styled.div`
  display: flex;
  justifyContent: space-between;
  gap: 12px;
`;

function FieldMappingContent({ fieldMapping, onToggle, onSubmit, isOpen }) {
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();
    onSubmit(e, fieldMapping.id);
  };

  const handleClick: React.MouseEventHandler<HTMLDivElement> = () => {
    onToggle(isOpen ? null : fieldMapping.id);
  };

  return (
    <div style={style.item} key={fieldMapping.id}>
      <div onClick={handleClick} style={style.itemSummary}>
        <div style={style.itemHeading}>
          {isOpen ? (
            <MinusIcon style={style.collapseIcon} />
          ) : (
            <AddIcon style={style.collapseIcon} />
          )}
          <span>{fieldMapping.label}</span>
        </div>
        <div style={style.itemStatus}>
          {fieldMapping.status === "needs_review" && (
            <>
              <AlertIcon style={style.fieldIcon} />
              <span>Needs review</span>
            </>
          )}
          {fieldMapping.status === "ignored" && (
            <>
              <span style={style.disabledText}>
                Not updated
                {fieldMapping.destinationUrl && (
                  <a
                    href={fieldMapping.destinationUrl}
                    target="_blank"
                    rel="noreferrer"
                  >
                    Go
                  </a>
                )}
              </span>
            </>
          )}
          {fieldMapping.status === "completed" && (
            <FlexCenter>
              <CheckmarkIcon
                style={{ ...style.fieldIcon, ...style.checkMarkIcon }}
              />
              <span>Added to CRM</span>
              {fieldMapping.destinationUrl && (
                <a
                  href={fieldMapping.destinationUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  <ExternalLink style={{ width: 16, color: colors.white }} />
                </a>
              )}
            </FlexCenter>
          )}
        </div>
      </div>
      {isOpen && (
        <>
          <form id="ignoreField" onSubmit={handleSubmit}>
            <input type="hidden" name="disabled" value="true" />
          </form>
          <form style={style.itemDetail} onSubmit={handleSubmit}>
            <input type="hidden" name="state" value="attempt_to_send" />
            <span style={style.verticalDivider} />
            <Grid>
              <InputContainer>
                <label style={style.inputLabel} htmlFor="previous_value">
                  Current content in CRM
                </label>
                <EditableContentTextarea
                  style={{ ...style.input, ...style.inputPreview }}
                  placeholder={`${fieldMapping.label} content preview`}
                  id="previous_value"
                  defaultValue={fieldMapping.previousValue}
                  readOnly
                />
              </InputContainer>
              <InputContainer>
                <label style={style.inputLabel}>
                  Briefly new content from call
                </label>
                <EditableContentTextarea
                  style={style.input}
                  placeholder="Briefly AI output (editable)"
                  name="confirmed_value"
                  defaultValue={fieldMapping.suggestedValue}
                />
              </InputContainer>
              <Actions>
                {fieldMapping.status === "needs_review" && (
                  <Button
                    form="ignoreField"
                    $secondary
                    $small
                    $inline
                  >
                    Ignore
                  </Button>
                )}
                <Button
                  style={{ ...style.buttonSolid }}
                  disabled={fieldMapping.status === "completed"}
                  $small
                  $inline
                >
                  Save to {fieldMapping.label}
                </Button>
              </Actions>
            </Grid>
          </form>
        </>
      )
      }
    </div >
  );
}

export default FieldMappingContent;
