import React from 'react';

const CopyIcon = (props: React.HTMLAttributes<HTMLOrSVGElement>) => (
  <svg width="14" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M11.719 12.7191V3.81277H4.7488V12.7191H11.719ZM11.719 2.5617C12.0566 2.5617 12.3545 2.69078 12.6126 2.94894C12.8708 3.18723 12.9999 3.47518 12.9999 3.81277V12.7191C12.9999 13.0567 12.8708 13.3546 12.6126 13.6128C12.3545 13.8709 12.0566 14 11.719 14H4.7488C4.41121 14 4.11334 13.8709 3.85518 13.6128C3.59703 13.3546 3.46795 13.0567 3.46795 12.7191V3.81277C3.46795 3.47518 3.59703 3.18723 3.85518 2.94894C4.11334 2.69078 4.41121 2.5617 4.7488 2.5617H11.719ZM9.81263 0V1.28085H2.1871V10.1872H0.936035V1.28085C0.936035 0.943262 1.05518 0.64539 1.29348 0.387234C1.55164 0.129078 1.84951 0 2.1871 0H9.81263Z" fill="currentColor" />
  </svg>
)

export default CopyIcon;

