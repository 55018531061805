import { useState, useEffect, useCallback } from "react";
import { getUserAccessToken, csrfGet } from "../../utils/genericUtils";
import { BACKEND_URL } from "../../config/config";

export function useDataFromEndpoint(endpointUrl) {
  const [jsonData, setJsonData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchData = useCallback(async () => {
    setLoading(true);
    getUserAccessToken(async ({ access }) => {
      try {
        const response = await csrfGet(BACKEND_URL + endpointUrl, {
          cache: "no-cache",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + access,
          },
          referrerPolicy: "no-referrer",
        });

        if (!response.ok) {
          return;
        }

        const data = await response.json();
        setJsonData(data);
        setLoading(false);
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    });
  }, [endpointUrl]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return [jsonData, loading, error, fetchData];
}

export default useDataFromEndpoint;
