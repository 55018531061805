// ZoomOAuth.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { sendZoomCodeToBackend, getUserAccessToken, authCalendar, setupZoom } from '../utils/genericUtils'
import RobotPicture from '../images/robot_picture.png'
import RobotTranscribing from '../images/robot_transcribing.gif'
import ZoomIcon from '../images/zoom_icon.png'
import CalendarIcon from '../images/calendar_icon.png'
import { useUserData } from './hooks/useUserData';
import { GoogleLogin, useGoogleLogin } from '@react-oauth/google';
import { GoogleOAuthProvider } from '@react-oauth/google';
import GoogleCalendarIcon from '../images/google_calendar_icon.png'
import QuickCheck1Image from '../images/quick-check-1.png'
import QuickCheck2Image from '../images/quick-check-2.png'

function connectedToEmailBadge(email) {
  var icon = <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.78873 9.94231L10.1972 4.53386L9.35211 3.66062L4.78873 8.224L2.64789 6.08315L1.80282 6.92822L4.78873 9.94231ZM1.74648 2.70287C2.92958 1.51977 4.34742 0.928223 6 0.928223C7.65258 0.928223 9.06103 1.51977 10.2254 2.70287C11.4085 3.86719 12 5.27564 12 6.92822C12 8.5808 11.4085 9.99864 10.2254 11.1817C9.06103 12.3461 7.65258 12.9282 6 12.9282C4.34742 12.9282 2.92958 12.3461 1.74648 11.1817C0.58216 9.99864 0 8.5808 0 6.92822C0 5.27564 0.58216 3.86719 1.74648 2.70287Z" fill="#80BF07" />
  </svg>

  return <div className="connected-to-email-badge">
    {icon}

    {email ? email : 'Connected'}
  </div>
}

function ZoomOnboardingStep1({ closePopup, goToPrevStep, goToNextStep }) {
  return <div>
    <h1 style={{ marginBottom: 20, "color": "#FFF", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "24px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>
      Set up your Briefly note-taker
    </h1>
    <div style={{ display: 'flex' }}>
      <div className="left-sidebar" style={{}}>
        <span style={{ marginBottom: 18, display: 'flex', justifyContent: 'center', alignItems: 'center', "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
          <img src={ZoomIcon} width="27" />
          <span style={{ marginLeft: 7, position: 'relative', top: 1, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
            Zoom Preview
          </span>
        </span>

        <div className="robot-container" style={{ marginBottom: 12, maxWidth: '100%', overflowX: 'hidden', margin: "auto", paddingTop: 25, paddingBottom: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: 200, maxHeight: 167, "borderRadius": "14px", "background": "var(--gradient-horizontal-dark, linear-gradient(90deg, #B700AF 0%, #F16617 100%))" }}>
          <img src={RobotPicture} width="80" />
          {/*<img src={RobotTranscribing} width="132" style={{ opacity: isTranscribing ? 1 : 0 }}/>*/}
        </div>

        <div style={{ marginTop: 12, "color": "#FFF", "textAlign": "center", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          Briefly note-taker
        </div>
      </div>
      <div style={{ paddingLeft: 25 }}>
        <h6 style={{ margin: 0, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
          How does it work?
        </h6>
        <h6 style={{ margin: 0, marginTop: 8, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          🤖 Invite bot@brieflyai.com to your calendar
        </h6>
        <h6 style={{ margin: 0, marginTop: 8, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          ✍️ Meeting starts
        </h6>
        <p style={{ margin: 0, marginTop: 4, "color": "var(--text-text-secondary, #C6C6C6)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          Your note-taker will join automatically and start transcribing
        </p>
        <h6 style={{ margin: 0, marginTop: 8, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          ️🛡️ Recording permission
        </h6>
        <p style={{ margin: 0, marginTop: 4, "color": "var(--text-text-secondary, #C6C6C6)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          The note-taker will ask the host to allow recording
        </p>
        <h6 style={{ margin: 0, marginTop: 8, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          📢 Informing participants
        </h6>
        <p style={{ margin: 0, marginTop: 4, "color": "var(--text-text-secondary, #C6C6C6)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          Attendees will be messaged letting them know you are using Briefly
        </p>

      </div>
    </div>
    <div style={{ textAlign: 'center', marginTop: 20 }}>
      <button onClick={goToNextStep} style={{ marginRight: 0, "display": "inline-flex", "padding": "8px 32px", "justifyContent": "center", "alignItems": "center", "gap": "8px", "borderRadius": "4px", "background": "var(--surface-surface-brand, #E44867)" }}>
        Start Zoom integration setup
      </button>
      <div style={{}} />
      <button onClick={closePopup} style={{ marginRight: 0, background: 'transparent', "color": "var(--color-brand-briefly, #E44867)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "600", "lineHeight": "16px", "textDecorationLine": "underline" }}>
        Quit Setup
      </button>
    </div>
  </div>
}

function ZoomOnboardingStep2({ closePopup, goToPrevStep, goToNextStep }) {

  const [{ is_integration_active_google_calendar, email_google_calendar }, forceRenewUserData] = useUserData()

  function onCalendarLoginSuccess(data) {
    authCalendar(data.code, calendarData => {
      setTimeout(() => {
        forceRenewUserData()
      }, 500)
    })
  }

  const calendarLogin = useGoogleLogin({
    onSuccess: codeResponse => onCalendarLoginSuccess(codeResponse),
    flow: 'auth-code',
    scope: "openid profile email https://www.googleapis.com/auth/calendar.readonly"
  });

  return <div style={{ textAlign: 'center' }}>
    <img src={CalendarIcon} width="100" style={{ marginTop: 20 }} />
    <h1 style={{ "color": "#FFF", "textAlign": "center", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "24px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>
      {is_integration_active_google_calendar ? 'Connection successful!' : 'Connect your calendar'}
    </h1>
    <p style={{ "color": "var(--text-text-primary, #FFF)", "textAlign": "center", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
      {is_integration_active_google_calendar ? <>
        Your calendar is now linked to {connectedToEmailBadge(email_google_calendar)}
      </> : 'Briefly uses your calendar to know which meetings to join and identify meeting participants.'}
    </p>

    <div style={{ textAlign: 'center', marginTop: 20 }}>
      {is_integration_active_google_calendar ? <button onClick={goToNextStep} style={{ marginRight: 0, "display": "inline-flex", "padding": "8px 32px", "justifyContent": "center", "alignItems": "center", "gap": "8px", "borderRadius": "4px", "background": "var(--surface-surface-brand, #E44867)" }}>
        Setup Briefly note-taker
      </button> : <button onClick={calendarLogin} style={{ marginRight: 0, "display": "inline-flex", "padding": "8px 32px", "justifyContent": "center", "alignItems": "center", "gap": "8px", "borderRadius": "4px", "background": "var(--surface-surface-brand, #E44867)" }}>
        <img src={GoogleCalendarIcon} height="18" style={{ position: 'relative', left: -3 }} /> Connect Google Calendar
      </button>}
      <div style={{}} />
      <button onClick={closePopup} style={{ marginRight: 0, background: 'transparent', "color": "var(--color-brand-briefly, #E44867)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "600", "lineHeight": "16px", "textDecorationLine": "underline" }}>
        Quit Setup
      </button>
    </div>
  </div>
}

function ZoomOnboardingStep3({ closePopup, goToPrevStep, goToNextStep, setBotShouldJoinAllCalls, botShouldJoinAllCalls }) {

  const navigate = useNavigate()

  return <div>
    <>
      <h1 style={{ marginBottom: 20, "color": "#FFF", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "24px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>
        Set up your Briefly note-taker
      </h1>
      <h6 style={{ marginTop: 25, marginBottom: 20, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
        How do you want Briefly note-taker to join your Zoom calls?
      </h6>
      <div>
        <div style={{}}>
          <div className="hubspot-option" style={{
            background: !botShouldJoinAllCalls ? '#212121' : '#141414',
            borderRadius: 8,
            border: !botShouldJoinAllCalls ? '2px solid #E44867' : '1px solid #2C2C2C',
            display: 'flex',
            padding: 24,
            paddingLeft: 16,
            cursor: 'pointer'
          }} onClick={() => {
            setBotShouldJoinAllCalls(false)
          }}>
            <div style={{ display: 'inline-flex', width: 30 }}>
              {!botShouldJoinAllCalls ?
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="15" cy="15" r="14" fill="#141414" stroke="#E44867" strokeWidth="2" />
                  <circle cx="15" cy="15" r="5" fill="#E44867" />
                </svg>

                :
                <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="15.5" cy="15" r="14.5" fill="#212121" stroke="#2C2C2C" />
                </svg>
              }
            </div>
            <div style={{ display: 'inline-block', width: 'calc(100% - 40px)', marginLeft: 10 }}>
              <span>Invite as Needed</span>
              <br />
              <span style={{ display: 'inline-block', marginTop: 5 }}>
                You decide when Briefly note-taker joins by adding bot@brieflyai.com to meeting invites. Remember, the invite must include a Zoom meeting link.
              </span>
            </div>
          </div>

          <div className="hubspot-option" style={{
            background: botShouldJoinAllCalls ? '#212121' : '#141414',
            borderRadius: 8,
            border: botShouldJoinAllCalls ? '2px solid #E44867' : '1px solid #2C2C2C',
            display: 'flex',
            padding: 24,
            paddingLeft: 16,
            cursor: 'pointer',
            marginTop: 20
          }} onClick={() => {
            setBotShouldJoinAllCalls(true)
          }}>
            <div style={{ display: 'inline-flex', width: 30 }}>
              {botShouldJoinAllCalls ?
                <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="15" cy="15" r="14" fill="#141414" stroke="#E44867" strokeWidth="2" />
                  <circle cx="15" cy="15" r="5" fill="#E44867" />
                </svg>
                :
                <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="15.5" cy="15" r="14.5" fill="#212121" stroke="#2C2C2C" />
                </svg>
              }
            </div>
            <div style={{ display: 'inline-block', width: 'calc(100% - 40px)', marginLeft: 10 }}>
              <span>Automatic Attendance</span>
              <br />
              <span style={{ display: 'inline-block', marginTop: 5 }}>
                Briefly note-taker will automatically join any meeting in your calendar that contains a Zoom link. No need for manual invites.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
    <div style={{ textAlign: 'center', marginTop: 20 }}>
      {<button onClick={goToNextStep} style={{ marginRight: 0, "display": "inline-flex", "padding": "8px 32px", "justifyContent": "center", "alignItems": "center", "gap": "8px", "borderRadius": "4px", "background": "var(--surface-surface-brand, #E44867)" }}>
        Confirm and continue
      </button>}
      <div style={{}} />
      <button onClick={closePopup} style={{ marginRight: 0, background: 'transparent', "color": "var(--color-brand-briefly, #E44867)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "600", "lineHeight": "16px", "textDecorationLine": "underline" }}>
        Quit Setup
      </button>
    </div>
  </div>
}

function ZoomOnboardingStep4({ closePopup, goToPrevStep, goToNextStep }) {
  const [isCheck2, setIsCheck2] = useState(false)

  if (!isCheck2) {
    return <div style={{ textAlign: 'center' }}>
      <div style={{ textAlign: 'left' }}>
        <h1 style={{ marginBottom: 20, "color": "#FFF", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "24px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>
          Quick check: Zoom App in Google Calendar
        </h1>
        <h6 style={{ marginTop: 25, marginBottom: 20, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
          Do you have the <span style={{ color: '#F97238' }}>Zoom add-on</span> in your Google Calendar?
        </h6>
        <p style={{ "color": "var(--text-text-secondary, #C6C6C6)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          Briefly note-taker will not join Zoom meetings if the add-on is not installed in your Google Workspace.
        </p>
      </div>
      <img src={QuickCheck1Image} width="342" style={{ borderRadius: 8 }} />
      <div style={{ textAlign: 'center', marginTop: 20 }}>
        <button onClick={() => window.open("https://workspace.google.com/marketplace/app/zoom_for_google_workspace/364750910244", '_blank').focus()} style={{ marginRight: 0, "display": "inline-flex", "padding": "8px 32px", "justifyContent": "center", "alignItems": "center", "gap": "8px", "borderRadius": "4px", "background": "var(--surface-surface-brand, #E44867)" }}>
          No, get Zoom add-on Now

          <svg style={{ top: 0, left: 0 }} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2598_1381)">
              <path d="M7.17578 1.33789C7.17578 1.01321 7.43899 0.75 7.76367 0.75H10.9658C11.1227 0.75 11.25 0.877262 11.25 1.03425V4.23633C11.25 4.56101 10.9868 4.82422 10.6621 4.82422V4.82422C10.3374 4.82422 10.0742 4.56101 10.0742 4.23633V2.74609L6.03512 6.72136C5.80809 6.9448 5.44335 6.94335 5.21811 6.71811V6.71811C4.99032 6.49032 4.99179 6.12054 5.22139 5.89457L9.25391 1.92578H7.76367C7.43899 1.92578 7.17578 1.66257 7.17578 1.33789V1.33789ZM8.76172 10.0742V6.58789C8.76172 6.26321 9.02493 6 9.34961 6V6C9.67429 6 9.9375 6.26321 9.9375 6.58789V10.0742C9.9375 10.3841 9.81901 10.6576 9.58203 10.8945C9.34505 11.1315 9.07161 11.25 8.76172 11.25H1.92578C1.59766 11.25 1.3151 11.1406 1.07812 10.9219C0.859375 10.6849 0.75 10.4023 0.75 10.0742V3.23828C0.75 2.91016 0.859375 2.63672 1.07812 2.41797C1.3151 2.18099 1.59766 2.0625 1.92578 2.0625H5.41211C5.73679 2.0625 6 2.32571 6 2.65039V2.65039C6 2.97507 5.73679 3.23828 5.41211 3.23828H1.92578V10.0742H8.76172Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_2598_1381">
                <rect width="12" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>


        </button>
        <button onClick={() => setIsCheck2(true)} style={{ marginRight: 0, marginLeft: 10, "display": "inline-flex", "padding": "8px 32px", "justifyContent": "center", "alignItems": "center", "gap": "8px", "borderRadius": "4px", "background": "var(--surface-surface-brand, #E44867)" }}>
          Yes, continue
        </button>
        <div style={{}} />
        <button onClick={closePopup} style={{ marginRight: 0, background: 'transparent', "color": "var(--color-brand-briefly, #E44867)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "600", "lineHeight": "16px", "textDecorationLine": "underline" }}>
          Quit Setup
        </button>
      </div>
    </div>
  } else {
    return <div style={{ textAlign: 'center' }}>
      <div style={{ textAlign: 'left' }}>
        <h1 style={{ marginBottom: 20, "color": "#FFF", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "24px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>
          Quick check: Captions in Zoom
        </h1>
        <h6 style={{ marginTop: 25, marginBottom: 20, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
          Are <span style={{ color: '#F97238' }}>automated captions</span> enabled on your Zoom calls?
        </h6>
        <p style={{ "color": "var(--text-text-secondary, #C6C6C6)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          Briefly note-taker relies on captions to transcribe your meetings accurately. Please make sure this feature is activated in your Zoom online settings.
        </p>
      </div>
      <img src={QuickCheck2Image} width="342" style={{ borderRadius: 8 }} />
      <div style={{ textAlign: 'center', marginTop: 20 }}>
        <button onClick={() => window.open("https://us02web.zoom.us/profile/setting", '_blank').focus()} style={{ marginRight: 0, "display": "inline-flex", "padding": "8px 32px", "justifyContent": "center", "alignItems": "center", "gap": "8px", "borderRadius": "4px", "background": "var(--surface-surface-brand, #E44867)" }}>
          No, update Zoom settings now

          <svg style={{ top: 0, left: 0 }} width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2598_1381)">
              <path d="M7.17578 1.33789C7.17578 1.01321 7.43899 0.75 7.76367 0.75H10.9658C11.1227 0.75 11.25 0.877262 11.25 1.03425V4.23633C11.25 4.56101 10.9868 4.82422 10.6621 4.82422V4.82422C10.3374 4.82422 10.0742 4.56101 10.0742 4.23633V2.74609L6.03512 6.72136C5.80809 6.9448 5.44335 6.94335 5.21811 6.71811V6.71811C4.99032 6.49032 4.99179 6.12054 5.22139 5.89457L9.25391 1.92578H7.76367C7.43899 1.92578 7.17578 1.66257 7.17578 1.33789V1.33789ZM8.76172 10.0742V6.58789C8.76172 6.26321 9.02493 6 9.34961 6V6C9.67429 6 9.9375 6.26321 9.9375 6.58789V10.0742C9.9375 10.3841 9.81901 10.6576 9.58203 10.8945C9.34505 11.1315 9.07161 11.25 8.76172 11.25H1.92578C1.59766 11.25 1.3151 11.1406 1.07812 10.9219C0.859375 10.6849 0.75 10.4023 0.75 10.0742V3.23828C0.75 2.91016 0.859375 2.63672 1.07812 2.41797C1.3151 2.18099 1.59766 2.0625 1.92578 2.0625H5.41211C5.73679 2.0625 6 2.32571 6 2.65039V2.65039C6 2.97507 5.73679 3.23828 5.41211 3.23828H1.92578V10.0742H8.76172Z" fill="white" />
            </g>
            <defs>
              <clipPath id="clip0_2598_1381">
                <rect width="12" height="12" fill="white" />
              </clipPath>
            </defs>
          </svg>


        </button>
        <button onClick={() => goToNextStep()} style={{ marginRight: 0, marginLeft: 10, "display": "inline-flex", "padding": "8px 32px", "justifyContent": "center", "alignItems": "center", "gap": "8px", "borderRadius": "4px", "background": "var(--surface-surface-brand, #E44867)" }}>
          Yes, continue
        </button>
        <div style={{}} />
        <button onClick={closePopup} style={{ marginRight: 0, background: 'transparent', "color": "var(--color-brand-briefly, #E44867)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "600", "lineHeight": "16px", "textDecorationLine": "underline" }}>
          Quit Setup
        </button>
      </div>
    </div>
  }


}

function ZoomOnboardingStep5({ closePopup, goToPrevStep, goToNextStep, botShouldJoinAllCalls }) {

  const navigate = useNavigate()

  return <div>
    <h1 style={{ marginBottom: 20, "color": "#FFF", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "\"Source Sans Pro\"", "fontSize": "24px", "fontStyle": "normal", "fontWeight": "900", "lineHeight": "normal" }}>
      You’re all set
    </h1>
    <div style={{ display: 'flex' }}>
      <div className="left-sidebar" style={{}}>
        <span style={{ marginBottom: 18, display: 'flex', justifyContent: 'center', alignItems: 'center', "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
          <img src={ZoomIcon} width="27" />
          <span style={{ marginLeft: 7, position: 'relative', top: 1, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
            Zoom Preview
          </span>
        </span>

        <div className="robot-container" style={{ marginBottom: 12, maxWidth: '100%', overflowX: 'hidden', margin: "auto", paddingTop: 25, paddingBottom: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between', width: 200, maxHeight: 167, "borderRadius": "14px", "background": "var(--gradient-horizontal-dark, linear-gradient(90deg, #B700AF 0%, #F16617 100%))" }}>
          <img src={RobotPicture} width="80" />
          {/*<img src={RobotTranscribing} width="132" style={{ opacity: isTranscribing ? 1 : 0 }}/>*/}
        </div>

        <div style={{ marginTop: 12, "color": "#FFF", "textAlign": "center", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          Briefly note-taker
        </div>
      </div>
      <div style={{ paddingLeft: 25 }}>
        <h6 style={{ margin: 0, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "16px", "fontStyle": "normal", "fontWeight": "500", "lineHeight": "22px", "letterSpacing": "0.15px" }}>
          How does it work?
        </h6>
        {!botShouldJoinAllCalls ? <h6 style={{ margin: 0, marginTop: 8, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          🤖 Invite bot@brieflyai.com to your calendar
        </h6> : ''}
        <h6 style={{ margin: 0, marginTop: 8, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          ✍️ Meeting starts
        </h6>
        <p style={{ margin: 0, marginTop: 4, "color": "var(--text-text-secondary, #C6C6C6)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          Your note-taker will join automatically and start transcribing
        </p>
        <h6 style={{ margin: 0, marginTop: 8, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          ️🛡️ Recording permission
        </h6>
        <p style={{ margin: 0, marginTop: 4, "color": "var(--text-text-secondary, #C6C6C6)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          The note-taker will ask the host to allow recording
        </p>
        <h6 style={{ margin: 0, marginTop: 8, "color": "var(--text-text-primary, #FFF)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          📢 Informing participants
        </h6>
        <p style={{ margin: 0, marginTop: 4, "color": "var(--text-text-secondary, #C6C6C6)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "400", "lineHeight": "20px", "letterSpacing": "0.15px" }}>
          Attendees will be messaged letting them know you are using Briefly
        </p>
        <p style={{ display: 'flex', background: '#212121', padding: '6px 8px', borderRadius: 4 }}>
          <span style={{ flex: 1, color: '#C6C6C6' }}>
            A summary email will be sent after the call ends as per your settings
          </span>
          <a onClick={() => {
            closePopup()
            navigate('/settings/ai-summary-email')
          }} style={{ cursor: 'pointer', "color": "var(--color-brand-briefly, #E44867)", "fontFeatureSettings": "'clig' off, 'liga' off", "fontFamily": "Roboto", "fontSize": "14px", "fontStyle": "normal", "fontWeight": "700", "lineHeight": "20px", "letterSpacing": "0.15px", "textDecorationLine": "underline" }}>
            Manage
          </a>
        </p>

      </div>
    </div>
    <div style={{ textAlign: 'center', marginTop: 20 }}>
      <button onClick={() => {
        closePopup()
        navigate('/settings/integrations/zoom')
      }} style={{ marginRight: 0, "display": "inline-flex", "padding": "8px 32px", "justifyContent": "center", "alignItems": "center", "gap": "8px", "borderRadius": "4px", "background": "var(--surface-surface-brand, #E44867)" }}>
        Complete Setup
      </button>
    </div>
  </div>
}

const STEPS_CONFIG = [
  {
    component: ZoomOnboardingStep1,
    label: 'Zoom setup'
  },
  {
    component: ZoomOnboardingStep2,
    label: 'Connect calendar'
  },
  {
    component: ZoomOnboardingStep3,
    label: 'Bot attendance'
  },
  {
    component: ZoomOnboardingStep4,
    label: 'Integration checks'
  },
  {
    component: ZoomOnboardingStep5,
    label: 'Complete setup'
  },
]

function ZoomOnboardingPopup({ onClose }) {
  const navigate = useNavigate();

  const [currentStepIndex, setCurrentStepIndex] = useState(0)

  const [_, forceRenewUserData] = useUserData()

  const [botShouldJoinAllCalls, setBotShouldJoinAllCalls] = useState(false)

  const currentStep = STEPS_CONFIG[currentStepIndex]
  const CurrentStepComponent = currentStep.component

  useEffect(() => {
    if (currentStepIndex === 4) {
      setupZoom(botShouldJoinAllCalls, () => {
        forceRenewUserData()
      })
    }
  }, [currentStepIndex])

  return <GoogleOAuthProvider clientId="576404817524-ss93f3dua36hvaum9oi4vqrmu8anc6o3.apps.googleusercontent.com">
    <div className="transcript-info-popup-container zoom-onboarding">
      <div className="popup-background" onClick={() => onClose()}></div>
      <div className="popup-content" style={{ padding: 20 }}>

        <div className="header steps" style={{ marginBottom: 25 }}>
          {STEPS_CONFIG.map(({ label }, i) => {
            const stepIsActive = currentStepIndex >= i
            return <div className={"step" + (stepIsActive ? " active" : "")}>
              {i > 0 ? <div className="connector"></div> : ''}
              <div className="step-body">
                <div className="circle">{i + 1}</div>
                <div className="label">{label}</div>
              </div>

            </div>
          })}
        </div>

        <CurrentStepComponent botShouldJoinAllCalls={botShouldJoinAllCalls} setBotShouldJoinAllCalls={setBotShouldJoinAllCalls} closePopup={onClose} goToPrevStep={() => setCurrentStepIndex(currentStepIndex - 1)} goToNextStep={() => setCurrentStepIndex(currentStepIndex + 1)} />

      </div>
    </div>
  </GoogleOAuthProvider>
};

export default ZoomOnboardingPopup;
