import React, { useState } from 'react';
import { Container, Body, Title } from './style';
import SelectContactForm from './SelectContactForm';
import CreateContactForm from './CreateContactForm';
import { SelectOperation } from './types';

interface Props {
  onCancel: () => void,
  onSuccess: (salesforceContact: SalesforceContact) => Promise<void>,
}

export interface SalesforceContact {
  Id: string;
  Name: string;
  Account: {
    Id: string;
    Name: string;
  }
}

export default function ServiceContactForm({ onCancel, onSuccess }: Props): JSX.Element {
  const [selectOperation, setSelectOperation] = useState<SelectOperation>('choose');

  return <Container>
    <Title>Add a new contact to Salesforce</Title>
    <Body>Future meetings with this email address will automatically be saved to this contact.</Body>
    {selectOperation === 'choose' && <SelectContactForm onCancel={onCancel} onToggle={() => setSelectOperation('create')} onSuccess={onSuccess} />}
    {selectOperation === 'create' && <CreateContactForm onCancel={() => setSelectOperation('choose')} onSuccess={onSuccess} />}
  </Container>
}

