import React, { useState } from 'react'
import Radio from './controls/Radio'
import { sendUserFeedback } from '../utils/genericUtils'

export function FeedbackComponent() {

	const [isFeedbackPopupOpened, setIsFeedbackPopupOpened] = useState(false)
	const [isFeedbackPopupSubmitted, setIsFeebackPopupSubmitted] = useState(false)
	const [feedbackText, setFeedbackText] = useState("")
	const [isBug, setIsBug] = useState(false)
	

	if(!isFeedbackPopupOpened) {
		return <div id="feedback-icon-container" style={{
			borderRadius: '8px 8px 0 0',
			padding: '8px 16px',
			paddingBottom: 6,
			background: '#E44867',
			position: 'fixed',
			bottom: 0,
			left: 10,
			zIndex: 9999
		}}>
			<svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M9 3.60563C9.33448 3.60563 9.60563 3.33448 9.60563 3V3C9.60563 2.66552 9.33448 2.39437 9 2.39437H3C2.66552 2.39437 2.39437 2.66552 2.39437 3V3C2.39437 3.33448 2.66552 3.60563 3 3.60563H9ZM6.60563 7.21127C6.94012 7.21127 7.21127 6.94012 7.21127 6.60563V6.60563C7.21127 6.27115 6.94012 6 6.60563 6H3C2.66552 6 2.39437 6.27115 2.39437 6.60563V6.60563C2.39437 6.94012 2.66552 7.21127 3 7.21127H6.60563ZM3 4.19718C2.66552 4.19718 2.39437 4.46833 2.39437 4.80282V4.80282C2.39437 5.1373 2.66552 5.40845 3 5.40845H9C9.33448 5.40845 9.60563 5.1373 9.60563 4.80282V4.80282C9.60563 4.46833 9.33448 4.19718 9 4.19718H3ZM10.8169 0C11.1362 0 11.4085 0.122066 11.6338 0.366197C11.8779 0.591549 12 0.86385 12 1.1831V8.39437C12 8.71362 11.8779 8.99531 11.6338 9.23944C11.4085 9.48357 11.1362 9.60563 10.8169 9.60563H2.39437L0 12V1.1831C0 0.86385 0.112676 0.591549 0.338028 0.366197C0.58216 0.122066 0.86385 0 1.1831 0H10.8169Z" fill="white"/>
			</svg>
			<span style={{ position: 'relative', fontSize: 12, fontWeight: 400, color: 'white', bottom: 0, marginLeft: 8 }}>
				Got feedback or found a bug? <a onClick={() => setIsFeedbackPopupOpened(true)} style={{ textDecoration: 'underline', fontWeight: 600, cursor: 'pointer' }}>Get in touch</a>
			</span>
		</div>
	}

	if(isFeedbackPopupSubmitted) {
		return <div className="transcript-info-popup-container">
		<div className="popup-background" onClick={() => {setIsFeebackPopupSubmitted(false); setIsFeedbackPopupOpened(false)}}></div>

		<div className="popup-content">
		  <div className="close-icon" onClick={() => {setIsFeebackPopupSubmitted(false); setIsFeedbackPopupOpened(false)}}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.1946 0.805369C15.6394 1.25016 15.6394 1.97131 15.1946 2.41611L9.61074 8L15.1946 13.5839C15.6394 14.0287 15.6394 14.7498 15.1946 15.1946C14.7498 15.6394 14.0287 15.6394 13.5839 15.1946L8 9.61074L2.41611 15.1946C1.97132 15.6394 1.25016 15.6394 0.805369 15.1946C0.360576 14.7498 0.360576 14.0287 0.805369 13.5839L6.38926 8L0.80537 2.41611C0.360577 1.97132 0.360576 1.25016 0.805369 0.805369C1.25016 0.360576 1.97131 0.360576 2.41611 0.805369L8 6.38926L13.5839 0.80537C14.0287 0.360577 14.7498 0.360576 15.1946 0.805369Z" fill="white"/>
            </svg>
          </div>
		  <h2>Thank you for your feedback!</h2>
		  <p>Your message was successfully shared with the team.</p>

		</div>


	</div>
	}

	return <div className="transcript-info-popup-container feedback-popup">
		<div className="popup-background" onClick={() => setIsFeedbackPopupOpened(false)}></div>

		<div className="popup-content">
		  <div className="close-icon" onClick={() => {
            setIsFeedbackPopupOpened(false)
          }}>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.1946 0.805369C15.6394 1.25016 15.6394 1.97131 15.1946 2.41611L9.61074 8L15.1946 13.5839C15.6394 14.0287 15.6394 14.7498 15.1946 15.1946C14.7498 15.6394 14.0287 15.6394 13.5839 15.1946L8 9.61074L2.41611 15.1946C1.97132 15.6394 1.25016 15.6394 0.805369 15.1946C0.360576 14.7498 0.360576 14.0287 0.805369 13.5839L6.38926 8L0.80537 2.41611C0.360577 1.97132 0.360576 1.25016 0.805369 0.805369C1.25016 0.360576 1.97131 0.360576 2.41611 0.805369L8 6.38926L13.5839 0.80537C14.0287 0.360577 14.7498 0.360576 15.1946 0.805369Z" fill="white"/>
            </svg>
          </div>
		  <h2>We Value Your Input!</h2>
		  <p>Whether you have a suggestion or encountered an issue, we're here to listen.</p>

		  <div>
		  	<Radio isChecked={!isBug} setIsChecked={() => setIsBug(false)} label="Send us feedback"/>
		  	<Radio isChecked={isBug} setIsChecked={() => setIsBug(true)} label="Report a bug"/>
		  </div>

		  <textarea style={{ height: 100 }} placeholder="Tell us how we can improve or what you love..." value={feedbackText} onChange={e => setFeedbackText(e.target.value)}/>

		  <div>
		  	<button className="alt-color" onClick={() => setIsFeedbackPopupOpened(false)}>Cancel</button>
		  	<button onClick={() => {
				sendUserFeedback(feedbackText, isBug, false, () => {
		  			setIsFeebackPopupSubmitted(true)
		  			setIsBug(false)
		  			setFeedbackText("")
		  		})
		  	}}>Submit</button>
		  </div>
		</div>


	</div>
}

export default FeedbackComponent