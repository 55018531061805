import React from 'react';
import { copyToClipboard } from "../utils/genericUtils";
import IconWithTimeoutReplaceOnClick from '../utils/IconWithTimeoutReplaceOnClick';
import CopyIcon from './Icons/Copy';
import CheckmarkIcon from './Icons/Checkmark';

interface Props {
  text: string,
}

export default function CopyToClipboard({ text }: Props): JSX.Element {
  return <IconWithTimeoutReplaceOnClick
    onClick={() => copyToClipboard(text)}
    initialIcon={<CopyIcon color="#E44867" />}
    clickedIcon={<CheckmarkIcon color="#E44867" width="14px" height="14px" />}
  />
}
